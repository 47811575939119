import React, { Component } from "react";

export default class MobileLayout extends Component {
  constructor(props) {
    super(props);
    require('../site.scss');
  }

  render() {
    return <div className="mobile-view">
      <div className="mobile-unavailable">
        <div className="mb-4">
          We're sorry, this feature doesn't work on mobile browsers.
        </div>
        <div>
          Please download the
          <a href="https://www.apple.com/ios/app-store/" className="app-link"> iOS</a> or
          <a href="https://play.google.com/store/apps" className="app-link"> Android</a> or application to use this feature.
       </div>
      </div>
    </div>
  }
}