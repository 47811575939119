import React from 'react';
import { Link } from "react-router-dom";
import LogoImg from '../../assets/images/textlegal.png'

export const MobileLogo = () => (
  <div className="page-logo">
    <Link to="/">
      <img src={LogoImg} alt="logo" className="logo-default" style={{ maxWidth: "50px", width: '100%' }} />
    </Link>
    <div className="menu-toggler sidebar-toggler">
      <span></span>
    </div>
  </div >
)

export default MobileLogo;