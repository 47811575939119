import React, { Component } from 'react';
import apiClient from '../../components/Core/apiClient';
import Swal from "sweetalert2";
import Footer from '../../components/Footer/AccountFooter';

class Forgot extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email_error: false,
			forgot_email: "",
			forgot_msg: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
	};

	validateEmail(email) {
		const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		const result = pattern.test(email);
		if (result === true) {
			this.setState({
				email_error: false,
				email: email
			})
		} else {
			this.setState({
				email_error: true,
				email: ""
			})
		}
	};

	handleChange(event) {
		this.validateEmail(event.target.value);
		this.setState({ [event.target.name]: event.target.value })
	}

	handleForgotSubmit = event => {
		event.preventDefault();
		Swal.fire({
			title: 'Processing',
			html: 'We are processing...',
			onBeforeOpen: () => {
				Swal.showLoading()
			}
		});
		apiClient.post("api/Account/ForgotPassword?email=" + this.state.forgot_email).then(response => {
			Swal.close();
			this.setState({
				forgot_msg: "Please check your email to reset your password."
			});
		});
	}

	render() {
		let emailCssClass = "form-control";
		emailCssClass += this.state.email_error ? " is-invalid" : "";
		return (
			<div>
				<div className="bg-image">
					<div className="kt-header--fixed kt-header-mobile--fixed kt-aside--enabled kt-aside--fixed kt-page--loading">
						<div className="kt-grid kt-grid--ver kt-grid--root">
							<div className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--forgot" id="kt_login">
								<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
									<div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
										<div className="kt-login__container wh-container">
											<div className="rectangle_title"></div>
											<div className="kt-login__forgot forgot-section">
												{this.state.forgot_msg === "" ?
													<div className="kt-login__head">
														<h1 className="kt-login__title roboto-slab tl-black">Forgot Password</h1>
														<div className="kt-login__desc text-center font-weight-bold tl-black mt-4">Enter your email address and we will send you a code to reset your password.</div>
													</div>
													:
													<div className="kt-login__head">
														<div className="kt-login__desc text-center font-weight-bold tl-black mt-4">{this.state.forgot_msg}</div>
													</div>
												}
												{this.state.forgot_msg === "" ?
													<form onSubmit={this.handleForgotSubmit} className="kt-form" method="post">
														<div className="input-group">
															<label className="text-center font-weight-bold tl-black ">Email/Username</label>
															{this.state.email_error ? <div id="email_error" className="error invalid-feedback1">Valid email is required.</div> : ''}
															<input required className={emailCssClass} type="email" placeholder=" " name="forgot_email" id="forget_email" onChange={this.handleChange} />
														</div>
														<div className="kt-login__actions mt-5">
															<button type="submit" id="kt_login_forgot_submit" className="btn btn-shadow btn-pill bttn-success large">
																Send Password
															</button>
															<p className="tl-black font-weight-bold mt-4">Already have an account? &nbsp;
															<a href="/login" className="tl-black" style={{ whiteSpace: 'nowrap' }}>Sign In</a>
															</p>
														</div>
													</form>
													:
													""
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<footer className="footer-area-account">
						<Footer />
					</footer>
				</div>
			</div>
		);
	};
}

export default Forgot;