import React, { Component } from 'react';
import { Logo } from '../../components/';

class Header extends Component {
	render() {
		return (
			<div className="headerContent">
				<nav className="navbar navbar-area navbar-expand-lg navbar-light ">
					<div className="container-fluid">
						<div className="navbar-brand logo">
							<Logo />
						</div>
						{/* <div className="collapse navbar-collapse" id="apptidy"> */}
						<div>
							<ul className="navbar-nav" id="primary-menu">
								<li className="nav-item navbar-btn-wrapper">
									{/* <div className="nav-link boxed-btn blank" id="login-btn">
										<Link to="/login" >Sign In</Link> 
									</div> */}
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

export default Header;
