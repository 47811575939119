import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class AsideMenuItem extends Component {
    render() {
        const title = this.props.title;
        const link = this.props.link;
        return (
            <li className="kt-menu__item">
                <NavLink to={link} className="kt-menu__link kt-menu__toggle">
                    <span className="kt-menu__link-text">{title}</span>
                </NavLink >
            </li>
        );
    }
}

export default AsideMenuItem;