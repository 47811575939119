import React from "react"
import { compose, withProps, withStateHandlers } from "recompose"
import { withGoogleMap, GoogleMap, Marker, Polygon, Circle } from "react-google-maps"

const Map = props => {
    return (
        <GoogleMap zoom={Math.round(14 - Math.log(props.radius) / Math.LN2)} center={props.center} mapTypeId={"roadmap"}>
            {
                props.polygons.map(polygon => {
                    return (
                        <Polygon
                            path={polygon.coords}
                            key={polygon.key}
                            options={{
                                fillColor: polygon.used ? 'rgba(0,0,0,0)' : "#333",
                                fillOpacity: 0.4,
                                strokeColor: '#0984e3',
                                strokeOpacity: 0.6,
                                strokeWeight: 2,
                                title: "props.address"
                            }}
                            onClick={props.handleZipCode.bind(this, polygon.key)}
                        />
                    )
                })
            }
            {
                <Circle
                    center={props.center}
                    radius={1609.344 * props.radius}
                    options={{
                        strokeColor: "#ff0000",
                        strokeOpacity: 0.6,
                        strokeWeight: 2,
                        fillColor: 'rgba(0,0,0,0)',
                    }}
                />
            }
            {
                <Marker
                    position={props.center}
                    title={props.address}
                >
                </Marker>
            }
        </GoogleMap>
    );
};

const withGoogleMaps = compose(
    withProps({
        polygonRef: React.createRef(),
        googleMapURL: "https://maps.googleapis.com",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100vh` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withStateHandlers(
        props => (
            {
                handleZipCode(zipCode) {
                    props.handleZipCode(zipCode.split('_', 1)[0]);
                }
            })
    ),
    withGoogleMap
);

export default withGoogleMaps(Map);