import React, { Component } from 'react'
import axios from 'axios'
import { Button } from '../../components/Form'
import { SearchForm } from '../../components/SearchForm/SearchForm'
import MapComponent from '../../components/Map/map'
import apiClient from '../../components/Core/apiClient'

class SearchResult extends Component {
  constructor() {
    super()
    require('./search-result.scss')
    this.state = {
      polygons: [],
      zipCodes: [],
      usedZipCodes: [],

      shortCode: '',
      keywords: '',
      address: '',
      latitude: null,
      longitude: null,
      distance: '',

      currentZipCode: '',
      currentCity: '',
      currentState: '',
      errorZipCodes: false,

      searchFormHandleSubmit: null,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.saveSearchFormHandler = this.saveSearchFormHandler.bind(this)
    this.handleNewSearch = this.handleNewSearch.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleZipCode = this.handleZipCode.bind(this)
  }
  componentDidMount = () => {
    this.setState({
      shortCode: this.props.location.state.data.shortCode,
      keywords: this.props.location.state.data.keywords,
      address: this.props.location.state.data.address,
      latitude: this.props.location.state.data.latitude,
      longitude: this.props.location.state.data.longitude,
      distance: this.props.location.state.data.distance,
    })
    this.usedZipCodeList(this.props.location.state.data.shortCode, this.props.location.state.data.keywords)
    this.searchZipCode(this.props.location.state.data.distance, this.props.location.state.data.latitude, this.props.location.state.data.longitude)
  }
  async handleInputChange(data) {
    data.shortCode = data.shortCode === '' ? this.state.shortCode : data.shortCode
    data.keywords = data.keywords.length === 0 ? this.state.keywords : data.keywords
    data.address = data.address === '' ? this.state.address : data.address
    data.latitude = data.latitude === null ? this.state.latitude : data.latitude
    data.longitude = data.longitude === null ? this.state.longitude : data.longitude
    data.distance = data.distance === '' ? this.state.distance : data.distance
    this.setState({
      shortCode: data.shortCode,
      keywords: data.keywords,
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
      distance: data.distance,
    })
    await this.usedZipCodeList(data.shortCode, data.keywords)
    await this.searchZipCode(data.distance, data.latitude, data.longitude)
  }
  saveSearchFormHandler(handler) {
    this.setState({
      searchFormHandleSubmit: handler,
    })
  }
  async usedZipCodeList(shortCode, keywords) {
    let endpoint = 'api/Business/UsedZipCodes?shortCode=' + shortCode.value + '&keywords=' + keywords.value
    await apiClient.get(endpoint).then(async (response) => {
      if (response.status === 200) {
        await this.setState({ usedZipCodes: response.data })
      } else this.setState({ usedZipCodes: [] })
    })
  }
  async searchZipCode(radius, latitude, longitude) {
    await axios({
      // url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/location?radius=' + radius
      //     + '&latitude=' + latitude + '&longitude=' + longitude,
      url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/zipcode/location',
      params: { latitude: latitude, longitude: longitude, radius: radius },
      method: 'get',
      headers: {
        'X-RapidAPI-Host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
        'X-RapidAPI-Key': '50dd6f6f04msh065ffab2e0fd2d5p1a86adjsnb1bd857ca0ab',
      },
    })
      .then(async (response) => {
        let polygons = []
        let zipCodes = []
        await response.data.features.map((region) => {
          let used = this.state.usedZipCodes.indexOf(region.properties.zipCode) === -1 ? false : true
          if (!used) {
            zipCodes.push({
              key: region.properties.zipCode,
              properties: region.properties,
            })
          }
          return region.geometry.coordinates.map((coord, i) => {
            let coordArr = []
            if (coord.length === 1) coord[0].map((coodinate) => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
            else coord.map((coodinate) => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
            polygons.push({
              key: region.properties.zipCode + '_' + i,
              coords: coordArr,
              used: used,
            })
            return coordArr
          })
        })
        let errorZipCodes = zipCodes.length === 0 ? true : false
        this.setState({ zipCodes: zipCodes, errorZipCodes: errorZipCodes })
        this.setState({ polygons: polygons })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  async handleNewSearch() {
    await this.state.searchFormHandleSubmit()
  }
  handleZipCode(zipCode) {
    this.state.zipCodes.map((o) => {
      return o.key === zipCode
        ? this.setState({
            currentZipCode: o.properties.zipCode,
            currentCity: o.properties.city,
            currentState: o.properties.state,
          })
        : ''
    })
  }
  async handleSubmit() {
    await this.state.searchFormHandleSubmit()
    if (this.state.errorZipCodes) return
    this.props.history.push({
      pathname: '/contact-info',
      state: {
        data: {
          shortCode: this.state.shortCode,
          keywords: this.state.keywords,
          address: this.state.address,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          distance: this.state.distance,
          zipCodes: this.state.zipCodes,
          isNationWide: false,
        },
      },
    })
  }
  render() {
    if (!this.state.polygons.length) return null
    return (
      <>
        <div className="search-result-wrapper">
          <div className="container-fluid" style={{ padding: 0 }}>
            <div className="row no-gutters">
              <div className="col-lg-5">
                <div className="keyword-container btn-shadow" id="keyword-form">
                  <div className="row no-gutters title">
                    <h3 className="roboto-slab"> Search Results </h3>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-12" id="search-form">
                      <SearchForm
                        saveSearchFormHandler={this.saveSearchFormHandler}
                        handleInputChange={this.handleInputChange}
                        distance={this.state.distance}
                        selectedShortCode={this.state.shortCode}
                        selectedKeyword={this.state.keywords}
                        address={this.state.address}
                        isNationwide={false}
                        fromSearchResult={true}
                      />
                      <Button onSubmit={this.handleNewSearch} className="bttn-success btn-width btn-shadow btn-custom-padding">
                        New Search
                      </Button>
                    </div>
                  </div>
                  {/* START ZIP CODES */}
                  <div className="available-zips bg-white text-left">
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <h5 className="available-zip-title"> Available Zip Codes {this.state.errorZipCodes ? <i className="error-content">Required</i> : ''}</h5>
                      </div>
                    </div>
                    <div className="row no-gutters show-zips">
                      <div className="col-md-12">
                        {this.state.zipCodes.map((zipCode) => (
                          <button type="button" className="btn btn-secondary btn-pill btn-zipcode" key={zipCode.key} onClick={this.handleZipCode.bind(this, zipCode.key)}>
                            {zipCode.properties.zipCode}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="row no-gutters p-top-20">
                      <div className="col-md-12  current-address">
                        <label>
                          {' '}
                          ZIP:{' '}
                          <span id="current-zip">
                            {' '}
                            <strong> {this.state.currentZipCode} </strong>{' '}
                          </span>{' '}
                        </label>{' '}
                        <br />
                        <label>
                          {' '}
                          Cities:
                          <span id="current-city">
                            {' '}
                            <strong> {this.state.currentCity}</strong>{' '}
                          </span>{' '}
                        </label>{' '}
                        <br />
                        <label>
                          {' '}
                          State:
                          <span id="current state">
                            {' '}
                            <strong> {this.state.currentState} </strong>
                          </span>{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-12 current-address">
                      <Button onSubmit={this.handleSubmit} className="btn-width bttn-success btn-shadow ">
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <MapComponent
                  address={this.state.address}
                  center={{ lat: this.state.latitude, lng: this.state.longitude }}
                  polygons={this.state.polygons}
                  radius={this.state.distance}
                  handleZipCode={this.handleZipCode}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default SearchResult
