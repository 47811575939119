/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import SVGIcon from "../../components/Core/Icons/SVGIcons";
import { Dialog, Classes } from "@blueprintjs/core";
import appleImg from '../../assets/images/google.png';
import googleImg from '../../assets/images/apple.png';
import windowsImg from '../../assets/images/microsoft.png';

import Walner from '../../assets/images/walner.png';
import Merel from '../../assets/images/merel.png';

export class Features extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      popupIcon: '',
      popupHead: '',
      popupBody: '',
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen(type) {
    this.setState({
      isOpen: true
    });
    switch (type) {
      case "aboutus":
        this.setState({
          popupIcon: 'people',
          popupHead: 'About Us',
          popupBody: type
        });
        break;
      case "features":
        this.setState({
          popupIcon: 'cog',
          popupHead: 'Features/Services',
          popupBody: type
        });
        break;
      case "download":
        this.setState({
          popupIcon: 'download',
          popupHead: 'Our products',
          popupBody: type
        });
        break;
      case "questions":
        this.setState({
          popupIcon: 'help',
          popupHead: 'Frequently Asked Questions',
          popupBody: type
        });
        break;
      default:
        break;
    }
  }

  handleClose() {
    this.setState({
      isOpen: false
    });
  }

  render() {
    return (
      <div>
        <section className="why-choose-area why-choose-us-bg">
          <div className="container text-center">
            <div className="row contentOne">
              <div className="background-border bg-success"></div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <a href={null} onClick={() => this.handleOpen("aboutus")} >
                  <div className="single-why-us-item margin-top-60">
                    <div className="icon gdbg-1" >
                      {SVGIcon.users}
                    </div>
                    <div className="content">
                      <h5 className="title comfortaa">Our Team</h5>
                      <p>About Us</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <a href={null} onClick={() => this.handleOpen("features")} >
                  <div className="single-why-us-item margin-top-60 ">
                    <div className="icon gdbg-3">
                      {SVGIcon.settings}
                    </div>
                    <div className="content">
                      <h4 className="title">Learn More</h4>
                      <p>Features/Services</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="row contentTwo">
              <div className="background-border bg-success"></div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <a href={null} onClick={() => this.handleOpen("download")} >
                  <div className="single-why-us-item">
                    <div className="icon gdbg-2">
                      {SVGIcon.download}
                    </div>
                    <div className="content">
                      <h4 className="title">Download</h4>
                      <p>Our products</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <a href={null} onClick={() => this.handleOpen("questions")} >
                  <div className="single-why-us-item">
                    <div className="icon gdbg-4">
                      {SVGIcon.question}
                    </div>
                    <div className="content">
                      <h4 className="title">FAQ</h4>
                      <p>Frequently Asked Questions</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Dialog
          className="popup-container"
          icon={this.state.popupIcon}
          onClose={this.handleClose}
          title={this.state.popupHead}
          {...this.state}
        >
          <div className={Classes.DIALOG_BODY}>
            {this.state.popupBody === "aboutus" ?
              <div className="aboutus">
                <p className="content">Text Legal is a communication and texting service for attorneys to quickly and conveniently acquire clients that are looking for legal service in their area. </p>
                <h5>We are a collection of attorneys that have an extensive experience in:</h5>
                <div className="row experience">
                  <div className="col-md-6">
                    <ol>
                      <li>Alimony</li>
                      <li>Annulment</li>
                      <li>Child Custody</li>
                      <li>Father's Rights</li>
                      <li>Legal Separation</li>
                      <li>Prenuptial & Post Nuptial Agreements </li>
                      <li>Divorce</li>
                    </ol>
                  </div>
                  <div className="col-md-6">
                    <ol>
                      <li>Worker's compensation</li>
                      <li>Car Accidents</li>
                      <li>Birth Injury</li>
                      <li>Truck Accidents</li>
                      <li>Dog Bites</li>
                      <li>Social Security Disability</li>
                    </ol>
                  </div>
                </div>
                <hr></hr>
                <div className="col-lg-12 walner">
                  <h4>Jonathan Walner</h4>
                  <div className="row">
                    <div className="col-md-3">
                      <img src={Walner} alt="walner"/>
                    </div>
                    <div className="col-md-9">
                    <p> Mr. Walner is a Chicago injury attorney, whose practice primarily consists of pre-litigation matters involving investigation, documenting and proving our clients personal injury, wrongful death, nursing home and medical malpractice cases. He also handles an extensive amount of uninsured and under-insured motorist cases. </p>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="col-lg-12 walner">
                  <h4>Jonathan Merel</h4>
                  <div className="row">
                    <div className="col-md-3">
                      <img src={Merel} alt="merel"/>
                    </div>
                    <div className="col-md-9">
                    <p> Mr. Merel is an experienced attorney who advocates for his clients in all divorce and family law proceedings, including settlement negotiations and trials. He has a great deal of experience in obtaining favorable outcomes for his clients in multi-million dollar marital estates and contested custody suits. Jonathan has quickly grown the firm to become one of the premier divorce and family law firms in the Chicagoland area. </p>
                    </div>
                  </div>
                </div>
              </div>
              : this.state.popupBody === "features" ?
                <div className="features services">
                  <ol>
                    <li>Great and convenient tool to acquire leads and clients</li>
                    <li>Location-based keywords</li>
                    <li>Easily check which zip codes are available in the chosen service radius</li>
                    <li>First approved obtains the keyword for their business in the area</li>
                    <li>Land-lines, toll-free lines, VoIP line, and just regular new local numbers can be text-enabled</li>
                    <li>Available in mobile apps and Windows apps</li>
                    <li>Mobile application down</li>
                  </ol>
                </div>
                : this.state.popupBody === "download" ?
                  <div className="contact-form-area" id="contact">
                    <div className="container">
                      <div className="row">
                        <div className="col-4">
                          <div className="text-center">Android Application</div>
                        </div>
                        <div className="col-4">
                          <div className="text-center">iOS Application</div>
                        </div>
                        <div className="col-4">
                          <div className="text-center">Windows Application</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 text-center">
                          <a href="https://apps.apple.com/us/app/textlegal/id1490925546?ls=1" className="kt-store__badge mt-3" target="_blank" rel="noopener noreferrer">
                            <img src={googleImg} alt="Apple Store" />
                          </a>
                        </div>
                        <div className="col-4 text-center">
                          <a href="https://play.google.com/store/apps/details?id=com.icodice.textlegal" className="kt-store__badge mt-3" target="_blank" rel="noopener noreferrer">
                            <img src={appleImg} alt="Google Store" />
                          </a>
                        </div>
                        <div className="col-4 text-center">
                          <a href="https://www.textlegal.net/apps/TextLegalDesktop Setup 1.0.1.exe" className="kt-store__badge mt-3" target="_blank" rel="noopener noreferrer">
                            <img src={windowsImg} alt="Windows Store" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  : this.state.popupBody === "questions" ?
                    <div className="contact-form-area" id="contact">
                      <div className="container faq">
                      <h4> What is a short code and how do I obtain it? </h4>
                      <p>Short code is a short phone number, typically 5 or 6 digits long that can only be used for text messages (SMS) or MMS messages. It is a great marketing tool for sending text blasts. You will have to choose from the short codes that are provided for you in the drop down menu. </p>
                      <hr></hr>
                      <h4> What is a keyword and how do I obtain it? </h4>
                      <p>Keyword is like a secret word you need when you knock on a door. You can choose a new keyword and get it approved by Text Legal for the area you want to provide service for. Or you can check if the keyword in the drop down menu is available in the area you’re servicing. If someone already took that keyword in your area, then you will have to choose a different keyword. </p>
                      <hr></hr>
                      <h4>How do I text enable my landline phone number, toll-free phone number, or VoIP line?</h4>
                      <p>Just let us know and we will connect your number to our business texting software, and you can still receive calls the way you always have. The phone itself doesn’t receive texts; the number does through our software!</p>
                    </div>
                    </div>
                    : ""
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

export default Features;
