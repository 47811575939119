import React, { Component } from 'react'
import ReactTable from 'react-table'
import apiClient from '../../components/Core/apiClient'
import Select from 'react-select'
import moment from 'moment'
import { AsYouType } from 'libphonenumber-js'
import { DatePicker } from 'antd'

import 'antd/dist/antd.css'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import Workbook from 'react-excel-workbook'

const { RangePicker } = DatePicker

class UserRequestReport extends Component {
  /* Constructor */
  constructor(props) {
    super(props)
    require('react-table/react-table.css')
    require('react-notifications/lib/notifications.css')
    require('../../containers/reactTable.scss')
    require('./UserRequestReport.scss')
    this.state = {
      data: [],
      exportData: [],
      pages: null,
      loading: true,
      selectedBusiness: this.props.isAdmin === undefined ? 0 : null,
      tableState: null,
      total: 0,
      shortcodes: null,
      keywords: null,

      businessID: this.props.isAdmin === undefined ? null : [],
      startDate: moment().subtract(1, 'months'),
      endDate: moment(),
      shortCode: null,
      keyword: null,

      isAdmin: localStorage.getItem('roles').includes('Admin'),
    }

    this.fetchConversations = this.fetchConversations.bind(this)
    this.fetchCompanies = this.fetchCompanies.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleRangeChange = this.handleRangeChange.bind(this)
    this.handleShortCodeChange = this.handleShortCodeChange.bind(this)
    this.handleKeywordChange = this.handleKeywordChange.bind(this)
  }
  /* Event */
  componentDidMount() {
    this.fetchConversations()
    this.fetchCompanies()
    this.shortCodeList()
    this.keywordList()
  }
  /* Functions */
  fetchConversations(state) {
    this.setState({
      loading: true,
    })
    apiClient
      .post('api/Report/UserRequestReport', {
        businessIDs: this.state.businessID,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        shortCodes: this.state.shortCode,
        keywords: this.state.keyword,
        lastRowId: 0,
        sortColumn: !state || !state.sorted || state.sorted.length === 0 ? 'CreatedDate' : state.sorted[0]['id'],
        isDescending: !state || !state.sorted || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        perPage: !state || !state.pageSize ? 10 : state.pageSize,
        skip: !state ? 0 : state.page * state.pageSize,
      })
      .then((response) => {
        this.setState({
          data: response.data['entities'],
          pages: Math.ceil(parseInt(response.data['total']) / (!state || !state.pageSize ? 10 : state.pageSize)),
          loading: false,
          total: response.data['total'],
          tableState: state,
          lastRowId: response.data['lastRowId'],
        })
      })
      .catch((error) => {
        console.error(`Axios request failed: ${error}`)
        this.setState({
          loading: false,
        })
      })

    // For report table data
    apiClient
      .post('api/Report/UserRequestReport', {
        businessIDs: this.state.businessID,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        shortCodes: this.state.shortCode,
        keywords: this.state.keyword,
        lastRowId: 0,
        sortColumn: !state || !state.sorted || state.sorted.length === 0 ? 'CreatedDate' : state.sorted[0]['id'],
        isDescending: !state || !state.sorted || state.sorted.length === 0 ? false : state.sorted[0]['desc'],
        perPage: 1000000,
        skip: 0,
      })
      .then((response) => {
        this.setState({ exportData: response.data['entities'] })
      })
  }

  fetchCompanies() {
    apiClient
      .post(`api/Business/List`, {
        searchValue: '',
        lastRowId: 0,
        sortColumn: 'CreatedDate',
        isDescending: true,
        perPage: 1000,
      })
      .then((response) => {
        this.setState({
          companies: this.mapCompanies(response.data['entities']),
        })
      })
      .catch((error) => {
        console.error(`Axios request failed: ${error}`)
      })
  }

  mapCompanies(companies) {
    let res = []
    if (!companies || !companies.length) return res

    for (let i = 0; i < companies.length; i++) {
      res.push({ value: companies[i]['id'], label: companies[i]['name'] })
    }
    return res
  }

  shortCodeList() {
    let endpoint = this.state.isAdmin ? 'api/Business/ShortCodeList' : 'api/ShortCode/BusinessList'
    apiClient.get(endpoint).then((response) => {
      this.setState({
        shortcodes: response.data
          .filter((item, idx, self) => idx === self.findIndex((t) => t.name === item.name))
          .map((o) => {
            return { value: o.name, label: o.name }
          }),
      })
    })
  }

  keywordList() {
    let endpoint = this.state.isAdmin ? 'api/Business/KeywordList' : 'api/Keyword/BusinessList'
    apiClient.get(endpoint).then((response) => {
      this.setState({
        keywords: response.data
          .filter((item, idx, self) => idx === self.findIndex((t) => t.name === item.name))
          .map((o) => {
            return { value: o.name, label: o.name }
          }),
      })
    })
  }

  async handleCompanyChange(event) {
    let businessIDs = null
    if (event != null && event.length > 0) {
      businessIDs = []
      event.forEach((element) => {
        businessIDs.push(element.value)
      })
    }
    await this.setState({
      businessID: businessIDs,
    })
    this.fetchConversations(this.state.tableState)
  }

  async handleRangeChange(event) {
    if (event[0] === null || event[1] === null) return

    let inputStart = event[0]
    inputStart.startOf('day')

    let inputEnd = event[1]
    inputEnd.endOf('day')

    await this.setState({
      startDate: inputStart,
      endDate: inputEnd,
    })

    this.fetchConversations(this.state.tableState)
  }

  async handleShortCodeChange(event) {
    let shortCodes = null
    if (event != null && event.length > 0) {
      shortCodes = []
      event.forEach((element) => {
        shortCodes.push(element.value)
      })
    }
    await this.setState({
      shortCode: shortCodes,
    })
    this.fetchConversations(this.state.tableState)
  }

  async handleKeywordChange(event) {
    let keywords = null
    if (event != null && event.length > 0) {
      keywords = []
      event.forEach((element) => {
        keywords.push(element.value)
      })
    }
    await this.setState({
      keyword: keywords,
    })
    this.fetchConversations(this.state.tableState)
  }

  render() {
    const { data, exportData, pages, loading } = this.state
    return (
      <div>
        <div className="row mb-3">
          <div className="col-12">
            <h2 className="page-title roboto-slab text-left tl-black font-weight-bold">Reports</h2>
          </div>
        </div>
        <div className="reports-tools" style={{ zIndex: 100 }}>
          <div className="reports-tools-selection">
            {this.props.isAdmin === undefined ? (
              <div className="ddCompany">
                <React.Fragment>
                  <Select
                    name="companies"
                    onChange={this.handleCompanyChange}
                    options={this.state.companies}
                    isClearable={true}
                    placeholder="Choose a company"
                    selectedOption={this.state.businessID}
                    isMulti={true}
                  />
                </React.Fragment>
              </div>
            ) : (
              ''
            )}
            <div className="ddShortcode">
              <Select name="shortcodes" onChange={this.handleShortCodeChange} options={this.state.shortcodes} isClearable={true} placeholder="Choose a shortcode" isMulti={true} />
            </div>
            <div className="ddKeyword" style={{ marginRight: 15 }}>
              <Select name="keywords" onChange={this.handleKeywordChange} options={this.state.keywords} isClearable={true} placeholder="Choose a keyword" isMulti={true} />
            </div>
          </div>
          <div className="reports-tools-datepicker">
            <label className="kt-report__range" style={{ marginRight: '8px' }}>
              Date range:
            </label>
            <RangePicker
              allowClear={false}
              format="MM/DD/YYYY"
              style={{ height: 38 }}
              ranges={{
                'Past week': [moment().subtract(7, 'days'), moment()],
                'Past month': [moment().subtract(1, 'months'), moment()],
                'Past 3 months': [moment().subtract(3, 'months'), moment()],
                'Past 6 months': [moment().subtract(6, 'months'), moment()],
                'Past year': [moment().subtract(1, 'years'), moment()],
              }}
              defaultValue={[this.state.startDate, this.state.endDate]}
              onChange={this.handleRangeChange}
            />
          </div>
        </div>
        <div className="kt-portlet kt-report" style={{ borderRadius: '20px' }}>
          <div className="user-table-container">
            <ReactTable
              columns={[
                {
                  Header: 'Company',
                  id: 'BusinessName',
                  accessor: (d) => d.businessName,
                },
                {
                  Header: 'Keyword',
                  id: 'Text',
                  accessor: (d) => d.text,
                },
                {
                  Header: 'Short Code',
                  id: 'shortCode',
                  accessor: (d) => d.shortCode,
                },
                {
                  Header: 'Zip Code',
                  id: 'zipCode',
                  accessor: (d) => d.zipCode,
                },
                {
                  Header: 'Source',
                  id: 'Source',
                  accessor: (d) => d.source,
                  Cell: (props) => new AsYouType('US').input(props.value),
                },
                {
                  Header: 'Created Date',
                  id: 'createdDate',
                  accessor: (d) => d.createdDate,
                },
              ]}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              data={data}
              pages={pages} // Display the total number of pages
              loading={loading} // Display the loading overlay when we need it
              onFetchData={this.fetchConversations} // Request new data when things change
              defaultPageSize={10}
              className="-striped -highlight"
            />
            <div>
              <Workbook
                filename="UserRequestReport.xlsx"
                element={
                  <button className="btn btn-pill bttn-success btn-width btn-shadow search-button" style={{ marginTop: '20px' }}>
                    Export to excel
                  </button>
                }
              >
                <Workbook.Sheet data={exportData} name="Report">
                  <Workbook.Column label="Company" value="businessName" />
                  <Workbook.Column label="Keyword" value="text" />
                  <Workbook.Column label="Short Code" value="shortCode" />
                  <Workbook.Column label="Zip Code" value="zipCode" />
                  <Workbook.Column label="Source" value="source" />
                  <Workbook.Column label="Created Date" value="createdDate" />
                  <Workbook.Column label="Status" value="status" />
                </Workbook.Sheet>
              </Workbook>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserRequestReport
