import React, { Component } from "react";
import FormInput from "../../components/Form/FormInput";
import axios from "axios";
import apiClient from "../../components/Core/apiClient";
import Swal from "sweetalert2";

class LocationLink extends Component {
  constructor(props) {
    super(props);
    require("./style.scss");
    this.state = {
      id: "",
      value: "",
      zipCode: "",
      isSubmitted: false,
    };
    this.handleGetLocation = this.handleGetLocation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleInputChange = (event) => {
    this.setState({
      zipCode: event.target.value,
    });
  };
  handleGetLocation = async () => {
    var self = this;
    if (document.location.protocol === "https:" && navigator.geolocation != null) {
      await navigator.geolocation.getCurrentPosition(function (pos) {
        var coords, url;
        coords = pos.coords;
        url = "https://nominatim.openstreetmap.org/reverse?format=json&lat=" + coords.latitude + "&lon=" + coords.longitude + "&addressdetails=1";
        axios({
          url: url,
          method: "get",
        })
          .then(async (response) => {
            await self.setState({ zipCode: response.data.address.postcode });
            self.handleSubmit();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };
  handleSubmit() {
    apiClient
      .post("api/Conversations/" + this.props.match.params.id + "/Location", {
        id: this.props.match.params.id,
        zipCode: this.state.zipCode,
      })
      .then(() => {
        Swal.fire({
          type: "success",
          title: "Success",
          text: "Your request has been completed.",
        }).then(() => {
          this.setState({
            isSubmitted: true,
          });
        });
      });
  }
  render() {
    const { zipCode, isSubmitted } = this.state;
    return (
      <div>
        <div className="locationLink">
          <div className="bg-overlay"></div>
          <div className="locationLink-container text-center">
            {isSubmitted ? (
              <div>
                <h1 className="title" style={{ marginBottom: 20 }}>
                  Thank you
                </h1>
                <div className="desc">You will now be receiving a new text from us</div>
              </div>
            ) : (
              <>
                <h1 className="title">Thank you for texting us</h1>
                <div className="desc">
                Please enter your zip code below so that we may better assist you or click Use my Current Location.
                </div>
                <button
                  onClick={this.handleGetLocation}
                  className="locationBtn"
                  style={{ background: "transparent", border: "none", cursor: "pointer", outline: "none" }}
                >
                  <span>Use my current location</span>
                </button>
                <div className="or-text">OR</div>
                <div className="submitForm">
                  <FormInput
                    className="locationInput"
                    value={zipCode}
                    onChange={this.handleInputChange}
                    name="zipcode"
                    type="number"
                    placeholder="Enter zipcode"
                  />
                  <button onClick={this.handleSubmit} className="submitBtn">
                    SUBMIT
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default LocationLink;
