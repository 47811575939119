import React, { Component } from 'react';
import Swal from "sweetalert2";
import apiClient from '../Core/apiClient';
class CustomContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                firstName: "",
                lastName: "",
                email: "",
                message: ""
            },
            errors: {
                firstName: "",
                lastName: "",
                email: "",
                message: ""
            },
            validity: {
                firstName: false,
                lastName: false,
                email: false,
                message: false
            },
            isSending: false
        };
    }

    handleChange = ({ target }) => {
        const { values } = this.state;
        values[target.name] = target.value;
        this.setState({ values });
        this.handleValidation(target);
    };

    handleValidation = target => {
        const { name, value } = target;
        const fieldValidationErrors = this.state.errors;
        const validity = this.state.validity;
        const isEmail = name === "email";
        const isFirstName = name === "firstName";
        const isLastName = name === "lastName";
        const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const alphabetTest = /^[a-zA-Z ]*$/;

        validity[name] = value.length > 0;
        fieldValidationErrors[name] = validity[name]
            ? ""
            : `This is required and cannot be empty`;

        if (validity[name]) {
            if (isEmail) {
                validity[name] = emailTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `Email should be a valid email address`;
            }
            if (isFirstName || isLastName) {
                validity[name] = alphabetTest.test(value);
                fieldValidationErrors[name] = validity[name]
                    ? ""
                    : `Please enter alphabet characters only.`;
            }
        }

        this.setState({
            errors: fieldValidationErrors,
            validity: validity
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isSending: true });
        const { values, validity } = this.state;
        if (Object.values(validity).every(Boolean)) {
            Swal.fire({
                title: 'Processing',
                html: 'We are processing your request...',
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });
            let endpoint = "api/Business/SendContact";
            apiClient.post(endpoint, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                message: values.message,
            })
                .then(response => {
                    Swal.fire({
                        type: 'success',
                        title: 'Success',
                        text: 'Message has been successfully sent.',
                    }).then(() => {
                        this.props.onClose();
                    });
                }).catch(error => {
                    Swal.close();
                });
            this.setState({ isSending: false });
        } else {
            for (let key in values) {
                let target = {
                    name: key,
                    value: values[key]
                };
                this.handleValidation(target);
            }
            this.setState({ isSending: false });
        }
    };

    render() {
        const { values, errors, isSending } = this.state;
        return (
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div className="contact-area-wrapper text-center" id="contact">
                        <h3 className="title">Contact Us</h3>
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="firstName" className="font-weight-bold roboto-slab">First Name*</label>
                                        <input type='text' name='firstName' onChange={this.handleChange} value={values.firstName} className={`form-control ${errors.firstName ? "is-invalid" : ""}`} />
                                        <div className="invalid-feedback">{errors.firstName}</div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="lastName" className="font-weight-bold roboto-slab">Last Name*</label>
                                        <input type='text' name='lastName' onChange={this.handleChange} value={values.lastName} className={`form-control ${errors.lastName ? "is-invalid" : ""}`} />
                                        <div className="invalid-feedback">{errors.lastName}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="email" className="font-weight-bold roboto-slab">Email*</label>
                                        <input type='email' name='email' onChange={this.handleChange} value={values.email} className={`form-control ${errors.email ? "is-invalid" : ""}`} />
                                        <div className="invalid-feedback">{errors.email}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="message" className="font-weight-bold roboto-slab">Message*</label>
                                        <textarea name="message" cols="30" rows="3" className={`form-control ${errors.message ? "is-invalid" : ""}`} onChange={this.handleChange} value={values.message}>

                                        </textarea>
                                        <div className="invalid-feedback">{errors.message}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="text-center submit-btn btn btn-width btn-shadow btn-pill bttn-success"
                                        disabled={isSending}
                                    >
                                        {isSending ? "Please wait..." : "Send"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default CustomContactForm;