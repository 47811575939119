import React, { Component } from 'react'
import FormInput from '../../components/Form/FormInput'
import apiClient from '../../components/Core/apiClient'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { notification } from 'antd'

class BusinessCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phoneNumber: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      isDefault: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure that you want to save?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Processing',
          html: 'We are processing your request...',
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        event.preventDefault()
        apiClient
          .post('api/Business/Create', {
            name: this.state.name,
            phoneNumber: this.state.phoneNumber,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            isDefault: this.state.isDefault === 'on' ? true : false,
          })
          .then(() => {
            notification.success({ message: 'Business has been successfully saved!' })
            Swal.close()
            window.location.href = '/admin/businesses'
          })
          .catch((error) => {
            let errors = ''
            if (error.response.data.errors[''] !== undefined) {
              errors += error.response.data.errors[''][0]
            }
            if (error.response.data.errors['Name'] !== undefined) {
              errors += error.response.data.errors['Name'][0]
            }
            if (error.response.data.errors['PhoneNumber'] !== undefined) {
              errors += error.response.data.errors['PhoneNumber'][0]
            }
            if (error.response.data.errors['Address1'] !== undefined) {
              errors += error.response.data.errors['Address1'][0]
            }

            if (error.response.data.errors['Address2'] !== undefined) {
              errors += error.response.data.errors['Address2'][0]
            }

            if (error.response.data.errors['City'] !== undefined) {
              errors += error.response.data.errors['City'][0]
            }

            if (error.response.data.errors['State'] !== undefined) {
              errors += error.response.data.errors['State'][0]
            }

            if (error.response.data.errors['Zip'] !== undefined) {
              errors += error.response.data.errors['Zip'][0]
            }

            if (error.response.data.errors['IsDefault'] !== undefined) {
              errors += error.response.data.errors['IsDefault'][0]
            }

            this.setState({
              errors: errors,
            })
            notification.error({ message: `${error}` })
            console.error(`Axios request failed: ${error}`)
            Swal.close()
          })
      }
    })
  }

  render() {
    return (
      <div>
        <div className="business-list pb-2">
          <div className="business-list-header">
            <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Create new business</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div style={{ padding: '30px 40px', background: 'white', borderRadius: 25 }}>
              <form onSubmit={this.handleSubmit} className="kt-form">
                <div className="kt-portlet__body">
                  <div className="form-group form-group-last"></div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" name="name" required={true} placeholder="Business Name" label="Business Name" className="form-control" />
                    </div>
                    <div className="col-md-6">
                      <label>Phone Number *</label>
                      <InputMask onChange={this.handleChange} required={true} name="phoneNumber" placeholder="Phone Number" className="form-control" mask="999-999-9999" maskChar=" " />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" required={true} name="address1" placeholder="Business Address" label="Business Address" className="form-control" />
                    </div>
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" name="address2" placeholder="Suite" label="Suite " className="form-control" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" name="city" required={true} placeholder="City" label="City" className="form-control" />
                    </div>
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" name="zip" required={true} placeholder="Zip Code" label="Zip Code" className="form-control" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput onChange={this.handleChange} type="text" name="state" required={true} placeholder="State" label="State" className="form-control" />
                    </div>
                    <div className="col-md-6">
                      <label>Default Business</label>
                      <div className="kt-checkbox-list">
                        <label className="kt-checkbox kt-checkbox--bold">
                          <input onChange={this.handleChange} name="isDefault" type="checkbox" />
                          Is Default<span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <button type="submit" className="btn btn-width float-right btn-shadow button btn-pill btn-normal req-btn" style={{ background: '#4477ba', color: 'white' }}>
                      Save
                    </button>
                    <a href="/admin/businesses" className="btn btn-secondary btn-shadow btn-pill btn-norma">
                      Cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BusinessCreate
