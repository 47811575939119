import React, { Component } from 'react'
import ReactTable from 'react-table'
import apiClient from '../../components/Core/apiClient'
import Swal from 'sweetalert2'
import { NotificationContainer, NotificationManager } from 'react-notifications'

import SVGIcon from '../../components/Core/Icons/SVGIcons'

class Configuration extends Component {
  constructor(props) {
    super(props)
    require('react-table/react-table.css')
    require('react-notifications/lib/notifications.css')
    require('../../containers/reactTable.scss')
    require('./config.scss')
    //require("./style.scss")
    this.state = {
      keywords: [],
      keywordSearch: '',
      keyword: '',
      keywordLoading: true,
      KeywordTableState: null,
      errorKeyword: false,

      shortCodes: [],
      shortCodeSearch: '',
      shortCode: '',
      phoneNumber: '',
      errorPhoneNumber: false,
      errorShortCode: false,
      shortCodeLoading: true,
      shortCodeTableState: null,
    }

    this.handleChange = this.handleChange.bind(this)

    this.fetchKeywords = this.fetchKeywords.bind(this)
    this.AddKeyword = this.AddKeyword.bind(this)

    this.fetchShortCodes = this.fetchShortCodes.bind(this)
    this.AddShorCode = this.AddShorCode.bind(this)
  }

  componentDidMount() {}

  fetchKeywords(state) {
    apiClient
      .get('/api/Keyword/List')
      .then((response) => {
        this.setState({
          keywords: response.data,
          keywordLoading: false,
          KeywordTableState: state,
        })
      })
      .catch((error) => {
        console.log(`Axios request failed: ${error}`)
      })
  }

  AddKeyword() {
    if (this.state.keyword === '') {
      this.setState({ errorKeyword: true })
      return
    }

    if (this.state.keywords.map((x) => x.name.toLowerCase()).includes(this.state.keyword.toLowerCase())) {
      this.setState({ errorKeyword: true })
      NotificationManager.info('Already added.', 'Info')
      return
    }

    apiClient
      .post('/api/Keyword/Add', {
        name: this.state.keyword,
      })
      .then((response) => {
        this.setState({ keyword: '' })
        this.fetchKeywords(this.state.KeywordTableState)
        NotificationManager.success('Keyword has been successfully added!', 'Success')
      })
      .catch((error) => {
        NotificationManager.error(`${error}`, 'Error')
        console.log(`Axios request failed: ${error}`)
        Swal.close()
      })
  }

  deleteKeyword(name) {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure that you want to delete this keyword?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#57b848',
      cancelButtonColor: '#c9d1d8',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        apiClient
          .delete('/api/Keyword/' + name)
          .then((response) => {
            this.fetchKeywords(this.state.KeywordTableState)
            NotificationManager.success('Keyword has been successfully deleted!', 'Success')
          })
          .catch((error) => {
            NotificationManager.error(`Keyword is used in package!`, 'Error')
            console.log(`Axios request failed: ${error}`)
            Swal.close()
          })
      }
    })
  }

  fetchShortCodes(state) {
    apiClient
      .get('/api/ShortCode/List')
      .then((response) => {
        this.setState({
          shortCodes: response.data,
          shortCodeLoading: false,
          shortCodeTableState: state,
        })
      })
      .catch((error) => {
        console.log(`Axios request failed: ${error}`)
      })
  }

  AddShorCode() {
    if (this.state.phoneNumber === '') {
      this.setState({ errorPhoneNumber: true })
    }
    if (this.state.shortCode === '') {
      this.setState({ errorShortCode: true })
    }
    if (this.state.phoneNumber === '' || this.state.shortCode === '') return

    if (this.state.shortCodes.map((x) => x.phoneNumber).includes(this.state.phoneNumber)) {
      this.setState({ errorPhoneNumber: true })
      NotificationManager.info('Already added.', 'Info')
      return
    }
    apiClient
      .post('/api/ShortCode/Add', {
        name: this.state.shortCode,
        phoneNumber: this.state.phoneNumber,
      })
      .then((response) => {
        this.setState({ shortCode: '' })
        this.fetchShortCodes(this.state.shortCodeTableState)
        NotificationManager.success('Short code has been successfully added!', 'Success')
      })
      .catch((error) => {
        NotificationManager.error(`${error}`, 'Error')
        console.log(`Axios request failed: ${error}`)
        Swal.close()
      })
  }

  deleteShortCode(name) {
    Swal.fire({
      // title: 'Are you sure?',
      text: 'Are you sure that you want to delete this short code?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#57b848',
      cancelButtonColor: '#c9d1d8',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        apiClient
          .delete('/api/ShortCode/' + name)
          .then((response) => {
            this.fetchShortCodes(this.state.shortCodeTableState)
            NotificationManager.success('Short code has been successfully deleted!', 'Success')
          })
          .catch((error) => {
            NotificationManager.error(`${error}`, 'Error')
            console.log(`Axios request failed: ${error}`)
            Swal.close()
          })
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    if (name === 'keyword') this.setState({ errorKeyword: value === '' })
    if (name === 'shortCode') this.setState({ errorShortCode: value === '' })
    if (name === 'phoneNumber') this.setState({ errorPhoneNumber: value === '' })
  }

  render() {
    let classNameKeywordValid = 'form-control ' + (this.state.errorKeyword === true ? 'is-invalid' : 'is-valid')
    let classNameShortCodeValid = 'form-control ' + (this.state.errorShortCode === true ? 'is-invalid' : 'is-valid')
    let classNamePhoneNumberValid = 'form-control ' + (this.state.errorPhoneNumber === true ? 'is-invalid' : 'is-valid')
    let keywordData = this.state.keywords
    if (this.state.keywordSearch) {
      keywordData = keywordData.filter((row) => {
        return row.name.toLowerCase().includes(this.state.keywordSearch.toLowerCase())
      })
    }
    let shortCodeData = this.state.shortCodes
    if (this.state.shortCodeSearch) {
      shortCodeData = shortCodeData.filter((row) => {
        return row.name.toLowerCase().includes(this.state.shortCodeSearch.toLowerCase()) || row.phoneNumber.toLowerCase().includes(this.state.shortCodeSearch.toLowerCase())
      })
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="keyword-section keyword-list">
              <div className="keyword-section-header">
                <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Keywords ({keywordData.length})</h2>
              </div>
              <div className="keyword-list-tools">
                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="generalSearch"
                    value={this.state.keywordSearch}
                    onChange={(e) => this.setState({ keywordSearch: e.target.value })}
                  />
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                    <span>{SVGIcon.search}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="kt-portlet">
              <div className="kt-portlet__body user-table-container">
                <ReactTable
                  columns={[
                    {
                      Header: 'Keyword',
                      id: 'name',
                      accessor: (d) => d.name,
                    },
                    {
                      Header: 'Actions',
                      style: { textAlign: 'center' },
                      id: 'id',
                      sortable: false,
                      accessor: (d) => d.name,
                      Cell: (props) => [
                        <button onClick={() => this.deleteKeyword(props.value)} className="btn" key="delete-keyword" data-toggle="tooltip" title="Delete keyword">
                          {SVGIcon.trash}
                        </button>,
                      ],
                    },
                  ]}
                  data={keywordData}
                  loading={this.state.keywordLoading}
                  onFetchData={this.fetchKeywords}
                  defaultPageSize={10}
                  style={{ height: 600 }}
                  showPagination={true}
                  pageSizeOptions={[10, 25, 50, 100]}
                  className="-striped -highlight"
                />
                <div className="row add-field">
                  <div className="kt-input-icon kt-input col-3">
                    <input type="text" name="keyword" className={classNameKeywordValid} value={this.state.keyword} placeholder="keyword" onChange={this.handleChange} />
                  </div>
                  <div className="col">
                    <button onClick={() => this.AddKeyword()} className="add-btn btn bttn-success btn-pill " key="add-keyword">
                      {' '}
                      {SVGIcon.plus_o} Add Keyword
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="keyword-section shortcode-list">
              <div className="shortcode-list-header">
                <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Shortcodes ({shortCodeData.length})</h2>
              </div>
              <div className="shortcode-list-tools">
                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="generalSearch"
                    value={this.state.shortCodeSearch}
                    onChange={(e) => this.setState({ shortCodeSearch: e.target.value })}
                  />
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                    <span>{SVGIcon.search}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="kt-portlet">
              <div className="kt-portlet__body user-table-container">
                <ReactTable
                  columns={[
                    {
                      Header: 'Shortcode',
                      id: 'name',
                      accessor: (d) => d.name,
                    },
                    {
                      Header: 'Phone number',
                      id: 'phoneNumber',
                      accessor: (d) => d.phoneNumber,
                    },
                    {
                      Header: 'Actions',
                      style: { textAlign: 'center' },
                      id: 'id',
                      sortable: false,
                      accessor: (d) => d.name,
                      Cell: (props) => [
                        <button onClick={() => this.deleteShortCode(props.value)} className="btn" key="delete-keyword" data-toggle="tooltip" title="Delete keyword">
                          {SVGIcon.trash}
                        </button>,
                      ],
                    },
                  ]}
                  data={shortCodeData}
                  loading={this.state.shortCodeLoading} // Display the loading overlay when we need it
                  onFetchData={this.fetchShortCodes} // Request new data when things change
                  defaultPageSize={10}
                  style={{
                    height: '600px', // This will force the table body to overflow and scroll, since there is not enough room
                  }}
                  showPagination={true}
                  pageSizeOptions={[10, 25, 50, 100]}
                  className="-striped -highlight"
                />
              </div>
              <div className="row add-field">
                <div className="kt-input-icon kt-input col-3" style={{ marginRight: '10px' }}>
                  <input type="text" name="shortCode" className={classNameShortCodeValid} value={this.state.shortCode} placeholder="shortcode" onChange={this.handleChange} />
                </div>
                <div className="kt-input-icon kt-input col-3">
                  <input type="number" name="phoneNumber" className={classNamePhoneNumberValid} value={this.state.phoneNumber} placeholder="phone" onChange={this.handleChange} />
                </div>
                <div className="col">
                  <button onClick={() => this.AddShorCode()} className="add-btn btn bttn-success btn-pill" key="add-shortCode">
                    {' '}
                    {SVGIcon.plus_o} Add Shortcode
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    )
  }
}

export default Configuration
