import React, { Component } from 'react'
import FormInput from '../../components/Form/FormInput'
import apiClient from '../../components/Core/apiClient'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import Select from 'react-select'

export const USER_STATUS = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
]
class UserEdit extends Component {
  constructor(props) {
    super(props)
    require('./user.scss')
    require('react-notifications/lib/notifications.css')
    this.state = {
      companies: null,
      allRoles: null,
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      company: null,
      roles: null,
      status: null,
      id: this.props.match.params.id,
      // errors: ''
      errorEmail: false,
      errorPhoneNumber: false,
      errorFirstName: false,
      errorLastName: false,
      errorStatus: false,
      errorCompany: false,
      errorRoles: false,
    }
    this.fetchCompanies = this.fetchCompanies.bind(this)
    this.fetchRoles = this.fetchRoles.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.fetchEntity = this.fetchEntity.bind(this)
  }

  componentDidMount() {
    Swal.fire({
      title: 'Processing',
      html: 'Loading user',
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    this.fetchCompanies()
    this.fetchRoles()
    this.fetchEntity()
  }

  mapEntities(companies) {
    let res = []
    if (!companies || !companies.length) return res
    for (let i = 0; i < companies.length; i++) {
      res.push({ value: companies[i]['id'], label: companies[i]['name'] })
    }
    return res
  }

  convertRoles(roles) {
    let res = []
    if (!roles || !roles.length) return res

    for (let i = 0; i < roles.length; i++) {
      res.push(roles[i]['value'])
    }
    return res
  }

  mapRoles(roles) {
    let res = []
    if (!roles || !roles.length) return res

    for (let i = 0; i < roles.length; i++) {
      res.push({ value: roles[i]['id'], label: roles[i]['name'] })
    }
    return res
  }

  fetchEntity() {
    let endpoint = 'api/Account/' + this.props.match.params.id
    apiClient
      .get(endpoint)
      .then(async (response) => {
        Swal.close()
        this.setState({
          email: response.data['email'],
          phoneNumber: response.data['phoneNumber'],
          firstName: response.data['firstName'],
          lastName: response.data['lastName'],
          password: '',
          confirmPassword: '',
          status: USER_STATUS.filter((status) => status.value === response.data['status']),
          company: this.state.companies?.filter((company) => company.value === response.data['businessId'])[0],
          roles: this.mapRoles(response.data['roles']),
        })
      })
      .catch((error) => {
        Swal.close()
        console.log(`Axios request failed: ${error}`)
      })
  }

  async fetchCompanies() {
    let endpoint = 'api/Business/List'
    await apiClient
      .post(endpoint, {
        searchValue: '',
        lastRowId: 0,
        sortColumn: 'CreatedDate',
        isDescending: true,
        perPage: 1000,
      })
      .then(async (response) => {
        await this.setState({
          companies: this.mapEntities(response.data['entities']),
        })
      })
      .catch((error) => {
        console.log(`Axios request failed: ${error}`)
      })
  }

  async fetchRoles() {
    let endpoint = this.props.isAdmin === undefined ? 'api/Account/Roles' : 'api/Account/LimitedRoles'
    await apiClient
      .get(endpoint)
      .then(async (response) => {
        await this.setState({
          allRoles: this.mapEntities(response.data),
        })
      })
      .catch((error) => {
        console.log(`Axios request failed: ${error}`)
      })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    // TODO : Required fields
    // PhoneNumber
    if (name === 'phoneNumber') {
      const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      const result = pattern.test(value)
      this.setState({ errorPhoneNumber: !result })
    }
    // FirstName
    if (name === 'firstName') this.setState({ errorFirstName: value === '' })
    // LastName
    if (name === 'lastName') this.setState({ errorLastName: value === '' })
    // Email
    if (name === 'email') {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      )
      const result = pattern.test(value)
      if (result === true) {
        this.setState({
          errorEmail: false,
        })
      } else {
        this.setState({
          errorEmail: true,
        })
      }
    }
  }
  handleCompanyChange(selected) {
    this.setState({ company: selected })
    if (selected === null) {
      this.setState({ errorCompany: true })
    }
  }

  handleRoleChange(selected) {
    this.setState({ roles: selected, errorRoles: false })
    if (selected === null) {
      this.setState({ errorRoles: true })
    }
  }
  handleStatusChange(selected) {
    this.setState({ status: selected })
    if (selected === null) {
      this.setState({ errorStatus: true })
    }
  }

  handleSubmit(event) {
    if (this.state.errorStatus || this.state.errorEmail || this.state.errorLastName || this.state.errorFirstName || this.state.errorPhoneNumber || this.state.errorRoles || this.state.errorCompany) {
      return false
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure that you want to save?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#57b848',
        cancelButtonColor: '#c9d1d8',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Processing',
            html: 'We are processing your request...',
            onBeforeOpen: () => {
              Swal.showLoading()
            },
          })
          event.preventDefault()
          let endpoint = 'api/Account/Update'
          apiClient
            .post(endpoint, {
              email: this.state.email,
              phoneNumber: this.state.phoneNumber,
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              currentPassword: this.state.password,
              newPassword: this.state.confirmPassword,
              businessId: this.state.company.value,
              status: this.state.status.value,
              roles: this.convertRoles(this.state.roles),
              id: this.state.id,
            })
            .then((response) => {
              NotificationManager.success('User has been successfully updated!', 'Success')
              Swal.close()
            })
            .catch((error) => {
              let errors = ''
              if (error.response.data.errors[''] !== undefined) {
                errors += error.response.data.errors[''][0]
              }
              if (error.response.data.errors['Email'] !== undefined) {
                errors += error.response.data.errors['Email'][0]
              }
              if (error.response.data.errors['FirstName'] !== undefined) {
                errors += error.response.data.errors['FirstName'][0]
              }
              if (error.response.data.errors['LastName'] !== undefined) {
                errors += error.response.data.errors['LastName'][0]
              }

              if (error.response.data.errors['Status'] !== undefined) {
                errors += error.response.data.errors['Status'][0]
              }

              if (error.response.data.errors['BusinessId'] !== undefined) {
                errors += error.response.data.errors['BusinessId'][0]
              }

              if (error.response.data.errors['CurrentPassword'] !== undefined) {
                errors += error.response.data.errors['CurrentPassword'][0]
              }

              if (error.response.data.errors['NewPassword'] !== undefined) {
                errors += error.response.data.errors['NewPassword'][0]
              }

              if (error.response.data.errors['Roles'] !== undefined) {
                errors += error.response.data.errors['Roles'][0]
              }

              if (error.response.data.errors['Status'] !== undefined) {
                errors += error.response.data.errors['Status'][0]
              }

              if (error.response.data.errors['PhoneNumber'] !== undefined) {
                errors += error.response.data.errors['PhoneNumber'][0]
              }

              this.setState({
                errors: errors,
              })
              NotificationManager.error(`${error}`, 'Error')
              console.log(`Axios request failed: ${error}`)
              Swal.close()
            })
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="kt-portlet kt-user user-table">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title font-weight-bold">Update user details</h3>
                </div>
              </div>
              <form className="kt-form">
                <div className="kt-portlet__body">
                  <div className="form-group form-group-last"></div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput value={this.state.email} onChange={this.handleChange} type="email" name="email" placeholder=" " label="Email" className="form-control" />
                      {this.state.errorEmail ? <div className="error">Please enter your email address</div> : ''}
                    </div>
                    <div className="col-md-6">
                      <label>Phone Number *</label>
                      <InputMask
                        value={this.state.phoneNumber}
                        onChange={this.handleChange}
                        name="phoneNumber"
                        placeholder=" "
                        className="form-control custom-landing-form user-form"
                        mask="(999) 999-9999"
                        maskChar=" "
                      />
                      {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <FormInput value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" placeholder=" " label="First Name " className="form-control" />
                      {this.state.errorFirstName ? <div className="error">Please enter your first name</div> : ''}
                    </div>
                    <div className="col-md-6">
                      <FormInput value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" placeholder=" " label="Last Name" className="form-control" />
                      {this.state.errorLastName ? <div className="error">Please enter your last name</div> : ''}
                    </div>
                  </div>
                  <div className="form-group row">
                    {this.props.isAdmin === undefined ? (
                      <div className="col-md-6">
                        <label>Company</label>
                        <Select value={this.state.company} onChange={this.handleCompanyChange} placeholder="Choose a company" options={this.state.companies} />
                        {this.state.errorCompany ? <div className="error">Please choose company </div> : ''}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="col-md-6">
                      <label>Roles</label>
                      <Select value={this.state.roles} onChange={this.handleRoleChange} placeholder="Choose roles" name="roles" options={this.state.allRoles} isMulti={true} />
                      {this.state.errorRoles ? <div className="error">Please choose role(s)</div> : ''}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <label>Status</label>
                      <Select value={this.state.status} onChange={this.handleStatusChange} placeholder="Choose status" name="status" options={USER_STATUS} />
                      {this.state.errorStatus ? <div className="error">Please choose status</div> : ''}
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <button type="button" onClick={this.handleSubmit} className="btn btn-pill bttn-success btn-width btn-shadow">
                      Update
                    </button>
                    {this.props.isAdmin === undefined ? (
                      <a href="/admin/users" className="btn bg-light-grey btn-pill btn-width btn-shadow">
                        Cancel
                      </a>
                    ) : (
                      <a href="/users" className="btn bg-light-grey btn-pill btn-width btn-shadow">
                        Cancel
                      </a>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    )
  }
}

export default UserEdit
