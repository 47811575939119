import React, { Component } from 'react'
import ReactTable from 'react-table'
import apiClient from '../../components/Core/apiClient'
import { AsYouType } from 'libphonenumber-js'
import Moment from 'react-moment/dist/index'
import HttpStatus from 'http-status-codes/index'
import Conversation from './Conversation'
import SVGIcon from '../../components/Core/Icons/SVGIcons'
import { HubConnectionBuilder, HttpTransportType, LogLevel, JsonHubProtocol } from '@aspnet/signalr'

export default class Messaging extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    require('react-table/react-table.css')
    require('../../containers/reactTable.scss')
    require('./messaging.scss')
    this.state = {
      connection: null,
      data: [],
      conversations: [],
      selectedRow: null,
      canReply: false,
      pages: null,
      loading: true,
      total: 0,
      searchValue: '',
      tableState: null,
      handleAppendMessage: null,
      prevConvId: 0,
      currConvId: 0,
    }
    this.onRowClick = this.onRowClick.bind(this)
    this.checkPhoneNumber = this.checkPhoneNumber.bind(this)
    this.fetchConversations = this.fetchConversations.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.appendMessageHandler = this.appendMessageHandler.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.checkPhoneNumber()

    const endpoint = 'https://textlegal-backend.azurewebsites.net/message'
    const protocol = new JsonHubProtocol()
    const connection = new HubConnectionBuilder()
      .withUrl(endpoint, { transport: HttpTransportType.LongPolling | HttpTransportType.WebSockets })
      .withHubProtocol(protocol)
      .configureLogging(LogLevel.Information)
      .build()

    this.setState({ connection }, async () => {
      async function start() {
        try {
          await connection.start().then(() => console.log('Connection started!'))
        } catch (err) {
          console.error(err)
          setTimeout(() => start(), 5000)
        }
      }

      await start()

      this.state.connection.onclose(async () => {
        await start()
      })

      this.state.connection.on('DeliverMessage', (user, arg) => {
        this.fetchConversations(this.state.tableState)
        this.state.handleAppendMessage(arg)
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    this.state.connection.stop()
  }

  appendMessageHandler(handler) {
    this.setState({
      handleAppendMessage: handler,
    })
  }

  onRowClick = (action, row) => {
    const id = this.state.id
    this.setState({
      prevConvId: this.state.currConvId,
      currConvId: row.id,
    })
    if (id === row.id) {
      this.setState({ id: '' })
    } else {
      this.setState({
        selectedRow: row,
      })
      if (row.status === 2) this.updateStatus(row.id)
    }
  }

  updateStatus(id) {
    apiClient
      .post('api/Conversations/UpdateStatus', {
        id: id,
        status: 1,
      })
      .then((response) => {})
  }

  isRowSelected = (row) => {
    return this.state.selectedRow === row
  }

  checkPhoneNumber() {
    apiClient.get('api/Conversations/CheckNumber').then((response) => {
      this.setState({
        canReply: response.data,
      })
    })
  }

  async fetchConversations(state) {
    let endpoint = 'api/Conversations/Admin'
    this.setState({
      loading: true,
    })
    await apiClient
      .post(endpoint, {
        searchValue: this.state.searchValue === null ? '' : this.state.searchValue,
        lastRowId: 0,
        sortColumn: 'CreatedDate',
        isDescending: true,
        perPage: state.pageSize === undefined ? 10 : state.pageSize,
        skip: state.page * state.pageSize,
      })
      .then((response) => {
        if (this._isMounted) {
          if (response.status === HttpStatus.OK) {
            this.setState({
              data: response.data['entities'],
              conversations: response.data.entities,
              pages: Math.ceil(parseInt(response.data['total']) / state.pageSize),
              loading: false,
              total: response.data['total'],
              tableState: state,
            })
          }
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false,
        })
        console.log(`Axios request failed: ${error}`)
      })
  }

  async onSearch(e) {
    await this.setState({ searchValue: e.target.value })
    this.fetchConversations(this.state.tableState)
  }

  render() {
    const { data, pages, loading } = this.state
    const selectedRow = this.state.selectedRow
    const columns = [
      {
        Header: 'From',
        accessor: 'source',
        Cell: (props) => new AsYouType('US').input(props.value),
      },
      {
        Header: 'Shortcode',
        accessor: 'shortCode',
      },
      {
        Header: 'Keyword(s)',
        accessor: 'text',
      },
      {
        Header: 'ZIP code',
        accessor: 'zipCode',
      },
      {
        Header: 'Date',
        accessor: 'modifiedDate',
        Cell: (props) => <Moment format="MM/DD/YYYY h:mm A">{props.value}</Moment>,
      },
    ]
    return (
      <div>
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app kt-messaging">
          <div className="kt-grid__item kt-app__toggle kt-app__aside kt-app__aside--lg kt-app__aside--fit" id="kt_chat_aside" style={{ width: '60%' }}>
            <div className="messaging-container">
              <div className="messaging-container-header">
                <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">Inbox ({this.state.total})</h2>
              </div>
              <div className="messaging-container-tools">
                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                  <input type="text" className="form-control" placeholder="Search..." id="generalSearch" value={this.state.searchValue} onChange={this.onSearch} />
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                    <span>{SVGIcon.search}</span>
                  </span>
                </div>
              </div>
            </div>

            <ReactTable
              keyField="id"
              columns={columns}
              manual
              data={data}
              pages={pages}
              loading={loading}
              onFetchData={this.fetchConversations}
              defaultPageSize={10}
              className="-striped -highlight"
              getTdProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (event, handleOriginal) => {
                      event.stopPropagation()
                      this.onRowClick('click', rowInfo.row._original)
                    },
                  }
                }
                return {}
              }}
              appendMessage={this.appendMessage}
              getTrProps={(state, rowInfo, column) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    style: {
                      fontWeight: rowInfo.original.status === 2 ? 'bold' : 'normal',
                    },
                  }
                }
                return {}
              }}
            />
          </div>
          {selectedRow && <Conversation id={selectedRow.id} conversation={selectedRow} canReply={this.state.canReply} appendMessageHandler={this.appendMessageHandler} key={selectedRow.id} />}
        </div>
      </div>
    )
  }
}
