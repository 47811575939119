import React, { Component } from "react";
import DropDown from "../../components/Form/DropDown";
import apiClient from '../../components/Core/apiClient';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Swal from "sweetalert2";
import InputMask from 'react-input-mask';
import FormInput from "../../components/Form/FormInput";

class UserCreate extends Component {
    constructor(props) {
        super(props);
        require("./user.scss");
        require("react-notifications/lib/notifications.css");
        this.state = {
            companies: null,
            allRoles: null,
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            company: this.props.company,
            roles: this.props.roles,
            // errors: ''
            // TODO : Error messages
            errorFirstName: false,
            errorLastName: false,
            errorEmail: false,
            errorPhoneNumber: false,
            errorPassword: false,
            errorConfirmPassword: false,
            errorCompanies: false,
            errorRoles: false
        };
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.fetchRoles = this.fetchRoles.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    };

    componentDidMount() {
        this.fetchCompanies();
        this.fetchRoles();
    }

    mapEntities(companies) {
        let res = [];
        if (!companies || !companies.length)
            return res;

        for (let i = 0; i < companies.length; i++) {
            res.push({ value: companies[i]["id"], label: companies[i]["name"] });
        }
        return res;
    }

    convertRoles(roles) {
        let res = [];
        if (!roles || !roles.length)
            return res;

        for (let i = 0; i < roles.length; i++) {
            res.push(roles[i]["value"]);
        }
        return res;
    }

    fetchCompanies() {
        let endpoint = "api/Business/List";
        apiClient.post(endpoint,
            {
                "searchValue": "",
                "lastRowId": 0,
                "sortColumn": "CreatedDate",
                "isDescending": true,
                "perPage": 1000
            }
        )
            .then(response => {
                this.setState({
                    companies: this.mapEntities(response.data["entities"])
                });
            }).catch(error => {
                console.log(`Axios request failed: ${error}`);
            });
    }

    fetchRoles() {
        let endpoint = this.props.isAdmin === undefined ? "api/Account/Roles" : "api/Account/LimitedRoles";
        apiClient.get(endpoint)
            .then(response => {
                this.setState({
                    allRoles: this.mapEntities(response.data)
                });
            }).catch(error => {
                console.log(`Axios request failed: ${error}`);
            });
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        // TODO : Required fields
        // PhoneNumber
        if (name === 'phoneNumber') {
            const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            const result = pattern.test(value);
            this.setState({ errorPhoneNumber: !result });
        }
        // FirstName
        if (name === 'firstName')
            this.setState({ errorFirstName: value === "" });
        // LastName
        if (name === 'lastName')
            this.setState({ errorLastName: value === "" });
        // Email
        if (name === 'email') {
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            const result = pattern.test(value);
            if (result === true) {
                this.setState({
                    errorEmail: false,
                })
            } else {
                this.setState({
                    errorEmail: true,
                })
            }
        }
        // Password
        if (name === 'password') {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!;@#$%^&*])(?=.{6,})/;
            const result = pattern.test(value);
            this.setState({ errorPassword: !result });
        }
        if (name === 'confirmPassword') {
            this.setState({ errorConfirmPassword: value !== this.state.password });
        }

    }
    validateForm() {
        if (this.state.firstName.length === 0)
            this.setState({ errorFirstName: true });
        if (this.state.lastName.length === 0)
            this.setState({ errorLastName: true });
        if (this.state.email.length === 0)
            this.setState({ errorEmail: true });
        if (this.state.phoneNumber.length === 0)
            this.setState({ errorPhoneNumber: true });
        if (this.state.password.length === 0)
            this.setState({ errorPassword: true });
        if (this.state.confirmPassword.length === 0)
            this.setState({ errorConfirmPassword: true });
        if (this.state.roles === undefined || this.state.roles === 0)
            this.setState({ errorRoles: true });
        if (this.props.isAdmin === undefined) {
            if (this.state.company === undefined || this.state.company === 0)
                this.setState({ errorCompany: true });
        }
    }

    handleCompanyChange(selected) {
        this.setState({ company: selected.value, errorCompany: false })
        if (selected.value === null) {
            this.setState({ errorCompany: true })
        }

    }

    handleRoleChange(selected) {
        // console.log(selected);
        this.setState({ roles: this.convertRoles(selected), errorRoles: false });
        if (selected === null)
            this.setState({ errorRoles: true })
    }

    async handleSubmit(event) {
        await this.validateForm();
        if (this.state.errorEmail || this.state.errorLastName || this.state.errorFirstName || this.state.errorPhoneNumber || this.state.errorRoles || this.state.errorCompany) {
            return false;
        }
        else {
            Swal.fire({
                // title: 'Are you sure?',
                text: "Are you sure that you want to save?",
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#c9d1d8',
                confirmButtonColor: '#57b848',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        title: 'Processing',
                        html: 'We are processing your request...',
                        onBeforeOpen: () => {
                            Swal.showLoading()
                        }
                    });
                    // event.preventDefault();
                    let endpoint = "api/Account/Create";
                    apiClient.post(endpoint, {
                        email: this.state.email,
                        phoneNumber: this.state.phoneNumber,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        password: this.state.password,
                        confirmPassword: this.state.confirmPassword,
                        businessId: this.state.company === null ? 0 : this.state.company,
                        roles: this.state.roles
                    })
                        .then(response => {
                            NotificationManager.success('User has been successfully saved!', 'Success');
                            this.setState({
                                allRoles: this.mapEntities(response.data)
                            });
                            Swal.close();
                        }).catch(error => {
                            let errors = "";
                            if (error.response.data.errors[""] !== undefined) {
                                errors += error.response.data.errors[""];
                            }
                            if (error.response.data.errors["Email"] !== undefined) {
                                errors += error.response.data.errors["Email"][0];
                            }
                            if (error.response.data.errors["FirstName"] !== undefined) {
                                errors += error.response.data.errors["FirstName"][0];
                            }
                            if (error.response.data.errors["LastName"] !== undefined) {
                                errors += error.response.data.errors["LastName"][0];
                            }

                            if (error.response.data.errors["Status"] !== undefined) {
                                errors += error.response.data.errors["Status"][0];
                            }

                            if (error.response.data.errors["BusinessId"] !== undefined) {
                                errors += error.response.data.errors["BusinessId"][0];
                            }

                            if (error.response.data.errors["CurrentPassword"] !== undefined) {
                                errors += error.response.data.errors["CurrentPassword"][0];
                            }

                            if (error.response.data.errors["NewPassword"] !== undefined) {
                                errors += error.response.data.errors["NewPassword"][0];
                            }

                            if (error.response.data.errors["Roles"] !== undefined) {
                                errors += error.response.data.errors["Roles"][0];
                            }

                            if (error.response.data.errors["PhoneNumber"] !== undefined) {
                                errors += error.response.data.errors["PhoneNumber"][0];
                            }

                            this.setState({
                                errors: errors
                            });
                            NotificationManager.error(`${errors}`, 'Error');
                            console.log(`Axios request failed: ${errors}`);
                            Swal.close();
                        });
                }
            })
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="kt-portlet kt-user user-table">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title font-weight-bold">Enter new user details</h3>
                                </div>
                            </div>
                            <form className="kt-form">

                                <div className="kt-portlet__body">
                                    <div className="form-group form-group-last">
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" required={true} label="First Name" className="form-control" />
                                            {this.state.errorFirstName ? <div className="error">Required</div> : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInput value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" required={true} label="Last Name" className="form-control" />
                                            {this.state.errorLastName ? <div className="error">Required</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.email} onChange={this.handleChange} type="email" name="email" required={true} label="Email/Username" className="form-control" />
                                            {this.state.errorEmail ? <div className="error">Valid email is required</div> : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <label>Phone Number *</label>
                                            <InputMask value={this.state.phoneNumber} onChange={this.handleChange} name="phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                                            {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <FormInput value={this.state.password} onChange={this.handleChange} type="password" name="password" required={true} label="Password" className="form-control" />
                                            {this.state.errorPassword ? <div className="error">At least 6 characters and contain at the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)</div> : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <FormInput value={this.state.confirmPassword} onChange={this.handleChange} type="password" name="confirmPassword" required={true} label="Confirm Password" className="form-control" />
                                            {this.state.errorConfirmPassword ? <div className="error">Password not match</div> : ''}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {this.props.isAdmin === undefined ?
                                            <div className="col-md-6 ">
                                                <label>Company</label>
                                                <DropDown name="company" onChange={this.handleCompanyChange} title="Company" options={this.state.companies} placeholder="Choose a company" />
                                                {this.state.errorCompany ? <div className="error">Please choose company </div> : ''}
                                            </div>
                                            :
                                            ""
                                        }
                                        <div className="col-md-6">
                                            <label>Roles</label>
                                            <DropDown name="roles" isMulti={true} onChange={this.handleRoleChange} options={this.state.allRoles} title="Roles" placeholder="Choose roles" />
                                            {this.state.errorRoles ? <div className="error">Please choose role(s)</div> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <button type="button" onClick={this.handleSubmit} className="btn btn-pill bttn-success btn-width btn-shadow">Save</button>
                                        {this.props.isAdmin === undefined ?
                                            <a href="/admin/users" className="btn bg-light-grey btn-pill btn-width btn-shadow">Cancel</a>
                                            :
                                            <a href="/users" className="btn bg-light-grey btn-pill btn-width btn-shadow">Cancel</a>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default UserCreate;