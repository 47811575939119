import React, { Component } from "react";
import apiClient from "../../components/Core/apiClient";
import HttpStatus from "http-status-codes";
import Message from "./Message";
import { AsYouType } from 'libphonenumber-js';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { Button, Popover, Position, Tag } from "@blueprintjs/core";
import InfiniteScroll from 'react-infinite-scroller';
import SVGIcon from "../../components/Core/Icons/SVGIcons";
import { notification } from 'antd';
import 'antd/dist/antd.css';

export const types = {
    sms: 'SMS',
    mms: 'MMS'
};

export default class Conversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            text: '',
            messages: [],
            canReply: this.props.canReply,
            hasMoreItems: true,
            lastRowId: 0,
            initing: false,
            hasFile: false,
            fileName: '',
        };
        this.handleClick = this.handleClick.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.addEmoji = this.addEmoji.bind(this);
    };

    componentDidMount() {
        this.props.appendMessageHandler(this.handleMessage);
        this.setState({ initing: true });
    }

    scrollToBottom() {
        var el = document.getElementById('text-body');
        el.scrollTop = el.scrollHeight - el.offsetHeight;
    };

    handleMessage(message) {
        if (message.conversationId === this.props.conversation.id) {
            this.setState({
                messages: [...this.state.messages, message], // update message list            
            });
            this.scrollToBottom();
        }
    }

    sendMessage(message) {
        apiClient.post(`api/Conversations/${message.conversationId}/Message`, {
            'conversationId': message.conversationId,
            'destination': message.destination,
            'text': message.text,
            'id': message.id,
            'urls': message.urls
        }).then(response => {
            if (response.status === HttpStatus.OK) {
                const messages = this.state.messages;
                // set message id
                let msg = messages.find(m => m.id === message.id);
                msg.id = response.data.id;
                msg.createdDate = response.data.createdDate;
                this.forceUpdate();
            }
        });
    }

    handleClick() {
        const text = this.state.text.trim();
        if (!this.state.hasFile && !text) {
            this.input.focus();
            return;
        }
        if (this.state.hasFile) {
            var file = document.getElementsByClassName("add-file-input")[0].files[0];
            this.handleUpload(file);
        } else {
            if (this.props.conversation) {
                const message = {
                    conversationId: this.props.conversation.id,
                    destination: this.props.conversation.source,
                    text: text,
                    urls: []
                };
                this.sendMessage(message);
                this.setState({
                    text: ''
                });
                var textfield = document.getElementById("txt-messaging");
                textfield.value = '';
            }
            this.input.focus();
        }
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.handleClick();
        }
    }

    async addEmoji(emoji) {
        var elem = document.getElementById("txt-messaging");
        var selectionPosition = elem.selectionStart;
        const newText = elem.value.substr(0, selectionPosition) + emoji.native + elem.value.substr(selectionPosition)
        elem.value = newText
        await this.setState({ text: newText })
    }

    loadItems() {
        var id = this.props.id;
        apiClient.post(`api/Conversations/${id}/Messages`,
            {
                "searchValue": '',
                "lastRowId": this.state.lastRowId,
                "sortColumn": 'CreatedDate',
                "isDescending": true,
                "perPage": 25
            }).then(response => {
                if (response.status === HttpStatus.OK) {
                    var entities = response.data.entities;
                    if (response.data.count === 0) return;
                    else {
                        var messages = this.state.messages.reverse();
                        // eslint-disable-next-line array-callback-return
                        entities.map((message) => {
                            messages.push(message);
                        });
                        messages = messages.reverse();
                        this.setState({
                            messages: messages,
                            lastRowId: messages[0].id
                        });
                    }
                }
            });

    }

    onChangeHandler(event) {
        if (event.target.files.length > 0) {
            this.setState({
                hasFile: true,
                fileName: event.target.files[0].name
            });
        }
    }

    removeFile() {
        this.setState({
            hasFile: false,
            fileName: ''
        });
        document.getElementsByClassName("add-file-input")[0].value = null;
    }

    handleUpload(file) {
        if (file) {
            if (file.size > 1000000) {
                this.removeFile();
                notification.error({
                    message: "File Too Large",
                    description: "Uploaded file is too large for MMS message!"
                })
            } else {
                const data = new FormData();
                data.append('file', file);
                apiClient.post('api/upload', data, {
                }).then(response => {
                    if (response.status === HttpStatus.OK) {
                        if (this.props.conversation) {
                            // create a new message
                            const message = {
                                conversationId: this.props.conversation.id,
                                destination: this.props.conversation.source,
                                text: this.state.text.trim(),
                                urls: response.data.filePaths
                            };
                            this.sendMessage(message);
                            this.setState({
                                text: ''
                            });
                            var textfield = document.getElementById("txt-messaging");
                            textfield.value = '';
                        }
                        this.removeFile();
                        this.input.focus();
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    render() {
        if (!this.state.initing)
            return null;
        const items = this.state.messages.map((message, i) => {
            return (
                <div className="track" key={i}>
                    <Message key={`message_${message.id}`} message={message} />
                </div>
            );
        });
        return <div className="kt-grid__item kt-grid__item--fluid kt-app__content" id="kt_chat_content">
            <div key="chat" className="kt-grid__item kt-grid__item--fluid kt-app__content" id="kt_chat_content">
                <div className="kt-chat">
                    <div className="kt-portlet kt-portlet--head-lg kt-portlet--last kt-portlet--head-custom">
                        <div className="kt-portlet__head">
                            <div className="kt-chat__head ">
                                <div className="kt-chat__center">
                                    <div className="kt-chat__label">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-chat__title">{new AsYouType('US').input(this.props.conversation.source)}</div>
                                                <span className="kt-chat__status">
                                                    {this.props.conversation.shortCode} &bull; {this.props.conversation.text} &bull; {this.props.conversation.zipCode}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__body">
                            <div id="text-body" style={{ height: "550px", overflow: "auto", paddingRight: "10px" }} >
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadItems.bind(this)}
                                    hasMore={this.state.hasMoreItems}
                                    loader={<div className="loader" key={0}></div>}
                                    isReverse={true}
                                    useWindow={false}
                                >
                                    <div className="kt-chat__messages">
                                        {items}
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-chat__input">
                                {
                                    this.state.hasFile ?
                                        <div className="kt-chat__toolbar">
                                            <div className="kt_chat__tools">
                                                <Tag
                                                    intent="primary"
                                                    interactive={true}
                                                    icon="paperclip"
                                                    round={true}
                                                    large={true}
                                                    onRemove={this.removeFile}
                                                >
                                                    {this.state.fileName}
                                                </Tag>
                                            </div>
                                        </div>
                                        : ""
                                }
                                {
                                    this.state.canReply ?
                                        <div className="kt-chat__editor message-editor">
                                            <div className="add-file">
                                                <label className="file-upload-label">
                                                    <input type="file" className="form-control add-file-input" onChange={this.onChangeHandler} style={{ display: "none" }} />
                                                    {SVGIcon.plus_o}
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                ref={(input) => { this.input = input; }}
                                                id="txt-messaging"
                                                onChange={async (event) => {
                                                    await this.setState({ text: event.target.value });
                                                }}
                                                onKeyDown={this.keyPress}
                                                value={this.state.text}
                                                placeholder="Type a message..."
                                                style={{
                                                    height: '41px',
                                                    resize: 'none',
                                                    overflow: 'hidden'
                                                }} />
                                            <div className="action-wrappers">
                                                <Popover className="dropdown-emoji-button" position={Position.TOP_RIGHT} >
                                                    <Button text={SVGIcon.smile} className="emoji-toggler" />
                                                    <div>
                                                        <Picker
                                                            onSelect={this.addEmoji}
                                                            title='Pick your emoji'
                                                            emoji='point_up'
                                                            native={true}
                                                            sheetSize={32}
                                                            showPreview={false}
                                                            showSkinTones={false}
                                                        />
                                                    </div>
                                                </Popover>
                                                <button type="button" className="btn kt-chat__reply" onClick={this.handleClick} >
                                                    {SVGIcon.send}
                                                </button>
                                            </div>
                                        </div> : <span style={{ color: "#b7b7b7" }}>If you want to reply to this message enter your phone number first</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }
}