import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PhoneImage from "../../components/Images/PhoneImage";
import { Logo } from '../../components/';
import { SearchForm } from '../../components/SearchForm/SearchForm';
import { Button } from "../../components/Form";
import Features from '../../components/Sections/Features';
import Footer from '../../components/Footer/Footer';
import PhoneImg from './images/group_twophones.png';
import VideoImg from './images/Text_Legal.jpg';
import appleImg from './images/google.png';
import googleImg from './images/apple.png';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    require("./landing.scss");
    this.state = {
      isLoading: true,
      searchFormHandleSubmit: null,
      isNationwide: true,
      shortCode: '',
      keywords: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveSearchFormHandler = this.saveSearchFormHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.callbackNationWide = this.callbackNationWide.bind(this);
  }

  handleInputChange(data) {
    if (data.isNationwide) {
      this.props.history.push({
        pathname: '/contact-info',
        state: { data }
      });
    } else
      this.props.history.push({
        pathname: '/search-result',
        state: { data }
      });
  }
  handleFormSubmit() {
    this.state.searchFormHandleSubmit();
  }
  saveSearchFormHandler(handler) {
    this.setState({
      searchFormHandleSubmit: handler
    });
  }
  callbackNationWide = (status) => {
    this.setState({
      isNationwide: status.isNationwide,
      shortCode: status.shortCode,
      keywords: status.keywords
    })
  }
  render() {
    return (
      <div>
        {/* <Header /> */}
        <header className="header-area header-bg" id="home">
          <div className="container-fluid">
            <div className="row">
              <div className="offset-md-1 col-md-10">

              </div>
            </div>
          </div>

          <div className='container-fluid' >
            <div className="row">
              <div className="offset-xl-1 col-md-4 headerItem">
                <Logo />
              </div>
              <div className="col-xl-6 col-md-8 text-centerr"
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-end"
                }}>
                <Link className="btn float-right bttn-success btn-pill btn-width btn-shadow" id="login-btn" to="/login" >Sign In</Link>
                <div className="video-wrap">
                  <iframe src="https://player.vimeo.com/video/377363902" width="350" height="200" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen title="textlegalvideo"></iframe>
                  {/* <p><a href="https://vimeo.com/377363902">textlegal</a> from <a href="https://vimeo.com/icodice">iCodice</a> on <a href="https://vimeo.com">Vimeo</a>.</p> */}
                  {/* <iframe src="https://www.icodice.com/textlegal" height="200" width="350"></iframe> */}
                </div>
                <div className="img-with-video">
                  <div className="img-wrap">
                    <img src={VideoImg} alt="Images of Video" />
                    <div className="hover">
                      <a href="https://vimeo.com/377363902" className="video-play-btn mfp-iframe" rel="noopener"><i className="fas fa-play"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-1"></div>
            </div>
          </div>
        </header>
        {/*  Search keyword and shortcode section */}
        <section className="counterup-area">
          <div className="container-fluid">
            <div className="row">
              <div className="d-none d-md-block col-md-5 position-relative">
                <PhoneImage image={PhoneImg} />
              </div>
              <div className="col search-content">
                <div className="row">
                  <div className="col-lg-12 col-md-10 col-sm-10 text-left search-title">
                    <h3>Search for Shortcode and Keywords availability in your area</h3>
                  </div>
                </div>
                <div className="row searchForm mt-2">
                  <div className="col-xl-4 col-lg-5">
                    <ul className="">
                      <li>
                        <b>Keywords:</b>
                        <div>DUI, Accident, Divorce...</div>
                      </li>
                      <li className="mt-2"> <b>Distance:</b>
                        <div> 5mile, 10mile...</div>
                      </li>
                      <li>
                        <a href="https://apps.apple.com/us/app/textlegal/id1490925546?ls=1" className="kt-store__width mt-4 app-store-link"><img src={googleImg} alt="Apple Store" /></a>
                        <br />
                        <a href="https://play.google.com/store/apps/details?id=com.icodice.textlegal" className="kt-store__width app-store-link"><img src={appleImg} alt="Google Store" /></a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-xl-8 col-lg-7'>
                    <SearchForm
                      saveSearchFormHandler={this.saveSearchFormHandler}
                      handleInputChange={this.handleInputChange}
                      address=""
                      parentCallbackNationWide={this.callbackNationWide}
                      isNationwide={true}
                    />
                  </div>
                </div>
                {this.state.isNationwide ?
                  <Button
                    className="bttn-success btn-width btn-shadow search-button"
                    onSubmit={this.handleFormSubmit} >
                    Continue
                  </Button>
                  :
                  <Button
                    className="bttn-success btn-width btn-shadow search-button"
                    onSubmit={this.handleFormSubmit} >
                    Search
                  </Button>
                }
              </div>
            </div>
          </div>
        </section>

        {/* Feature and Content area */}
        <section className="why-choose-area why-choose-us-bg">
          <Features />
        </section>

        {/* Contact Us section */}
        <section className="contact-form-area" id="contact">
          <div className="container">
            {/*<ContactForm />*/}
            {/* <CustomContactForm /> */}
            {/* <div className="ocean">
                          <div className="wave"></div>
                          <div className="wave"></div>
                        </div> */}
          </div>
        </section>
        {
          window.location.pathname === '/privacy-policy' ?
            <footer className="footer-area" >
              <Footer openStatus={true} openDialog="privacy" />
            </footer>
            : window.location.pathname === '/terms-of-service' ?
              <footer className="footer-area" >
                <Footer openStatus={true} openDialog="service" />
              </footer>
              : window.location.pathname === '/terms-of-use' ?
                <footer className="footer-area" >
                  <Footer openStatus={true} openDialog="terms" />
                </footer>
                : window.location.pathname === '/contact' ?
                  <footer className="footer-area" >
                    <Footer openStatus={true} openDialog="contact" />
                  </footer>
                  : <footer className="footer-area" >
                    <Footer />
                  </footer>
        }
      </div >
    );
  }
}

export default LandingPage;