import React, { Component } from 'react'
import ReactTable from 'react-table'
import apiClient from '../../components/Core/apiClient'
import Swal from 'sweetalert2'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import SVGIcon from '../../components/Core/Icons/SVGIcons'

class DataType extends Component {
  constructor(props) {
    super(props)
    require('react-table/react-table.css')
    require('react-notifications/lib/notifications.css')
    require('../../containers/reactTable.scss')
    require('./datatype.scss')
    this.state = {
      datatypes: [],
      datatypeSearch: '',
      datatype: '',
      datatypeLoading: true,
      DatatypeTableState: null,
      errorDatatype: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.fetchDatatypes = this.fetchDatatypes.bind(this)
    this.AddDatatype = this.AddDatatype.bind(this)
  }

  componentDidMount() {}

  fetchDatatypes(state) {
    apiClient
      .get('/api/Keyword/ListDataType')
      .then((response) => {
        this.setState({
          datatypes: response.data,
          datatypeLoading: false,
          DatatypeTableState: state,
        })
      })
      .catch((error) => {
        console.log(`Axios request failed: ${error}`)
      })
  }

  AddDatatype() {
    if (this.state.datatype === '') {
      this.setState({ errorDatatype: true })
      return
    }

    if (this.state.datatypes.map((x) => x.name.toLowerCase()).includes(this.state.datatype.toLowerCase())) {
      this.setState({ errorDatatype: true })
      NotificationManager.info('Already added.', 'Info')
      return
    }

    apiClient
      .post('/api/Keyword/AddDataType', {
        name: this.state.datatype,
      })
      .then((response) => {
        this.setState({ datatype: '' })
        this.fetchDatatypes(this.state.DatatypeTableState)
        NotificationManager.success('Datatype has been successfully added!', 'Success')
      })
      .catch((error) => {
        NotificationManager.error(`${error}`, 'Error')
        console.log(`Axios request failed: ${error}`)
        Swal.close()
      })
  }

  deleteDatatype(datatypeId) {
    Swal.fire({
      text: 'Are you sure that you want to delete this datatype?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#57b848',
      cancelButtonColor: '#c9d1d8',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        apiClient
          .delete('/api/Keyword/DeleteDataType/' + datatypeId)
          .then((response) => {
            this.fetchDatatypes(this.state.DatatypeTableState)
            NotificationManager.success('Datatype has been successfully deleted!', 'Success')
          })
          .catch((error) => {
            NotificationManager.error(`${error}`, 'Error')
            console.log(`Axios request failed: ${error}`)
            Swal.close()
          })
      }
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    if (name === 'datatype') this.setState({ errorDatatype: value === '' })
  }

  render() {
    let classNameDatatypeValid = 'form-control ' + (this.state.errorDatatype === true ? 'is-invalid' : 'is-valid')
    let datatypeData = this.state.datatypes
    if (this.state.datatypeSearch) {
      datatypeData = datatypeData.filter((row) => {
        return row.name.toLowerCase().includes(this.state.datatypeSearch.toLowerCase())
      })
    }
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="datatype-section datatype-list">
              <div className="datatype-section-header">
                <h2 className="page-title roboto-slab text-left tl-black font-weight-bold mb-3">
                  Data Types ({datatypeData.length})
                </h2>
              </div>
              <div className="datatype-list-tools">
                <div className="kt-input-icon kt-input-icon--right kt-subheader__search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="generalSearch"
                    value={this.state.datatypeSearch}
                    onChange={(e) => this.setState({ datatypeSearch: e.target.value })}
                  />
                  <span className="kt-input-icon__icon kt-input-icon__icon--right">
                    <span>{SVGIcon.search}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="kt-portlet">
              <div className="kt-portlet__body user-table-container">
                <ReactTable
                  columns={[
                    {
                      Header: 'Datatype',
                      id: 'name',
                      accessor: (d) => d.name,
                    },
                    {
                      Header: 'Actions',
                      style: { textAlign: 'center' },
                      id: 'id',
                      sortable: false,
                      accessor: (d) => d.id,
                      Cell: (props) => [
                        <button
                          onClick={() => this.deleteDatatype(props.value)}
                          className="btn"
                          key="delete-datatype"
                          data-toggle="tooltip"
                          title="Delete datatype"
                        >
                          {SVGIcon.trash}
                        </button>,
                      ],
                    },
                  ]}
                  data={datatypeData}
                  loading={this.state.datatypeLoading}
                  onFetchData={this.fetchDatatypes}
                  defaultPageSize={10}
                  style={{
                    height: '600px',
                  }}
                  showPagination={true}
                  pageSizeOptions={[10, 25, 50, 100]}
                  className="-striped -highlight"
                />
                <div className="row add-field">
                  <div className="kt-input-icon kt-input col-3">
                    <input
                      type="text"
                      name="datatype"
                      className={classNameDatatypeValid}
                      value={this.state.datatype}
                      placeholder="enter datatype"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col">
                    <button
                      onClick={() => this.AddDatatype()}
                      className="add-btn btn bttn-success btn-pill "
                      key="add-datatype"
                    >
                      {' '}
                      {SVGIcon.plus_o} Add Datatype
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    )
  }
}

export default DataType
