import React, { Component } from 'react';
import chroma from 'chroma-js';

import Select from 'react-select'

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    data.color = '#0052CC';
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
class DropDown extends Component {
  constructor() {
    super();
    this.state = {
      options: []
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <Select
        isClearable={this.props.isClearable}
        isMulti={this.props.isMulti}
        onChange={this.props.onChange}
        options={this.props.options}
        defaultValue={this.props.selectedOption}
        styles={colourStyles}
        placeholder={this.props.placeholder}
        name={this.props.name}
        required={true}
        {...this.props} 
      />
    );
  }
}

export default DropDown;