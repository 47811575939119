/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import FormInput from "../../components/Form/FormInput";
import Swal from "sweetalert2";
import apiClient from '../../components/Core/apiClient';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CardReactFormContainer from 'card-react';
import InputMask from "react-input-mask";
import Footer from '../../components/Footer/Footer';

class Profile extends Component {
  constructor(props) {
    super(props);
    require("react-notifications/lib/notifications.css");
    require("react-notifications/lib/notifications.css");
    require("./profile.scss");
    require('./card.css');
    this.state = {
      isPricingPlan: true,

      shortCode: '',
      keyword: '',
      address: '',
      radius: '',
      price: '',
      isNationWide: true,

      activeTab: 1,

      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',

      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPhoneNumber: false,
      errorPassword: false,
      errorConfirmPassword: false,

      business_name: '',
      business_phoneNumber: '',
      business_address1: '',
      business_address2: '',
      business_city: '',
      business_state: '',
      business_zip: '',

      error_business_name: false,
      error_business_phoneNumber: false,
      error_business_address1: false,
      error_business_city: false,
      error_business_state: false,
      error_business_zip: false,

      payment_cardnumber: '',
      payment_cvc: '',
      payment_expiry: '',
      payment_name: '',

      payment_address: '',
      payment_city: '',
      payment_state: '',
      payment_zip: '',

      error_payment_address: '',
      error_payment_city: '',
      error_payment_state: '',
      error_payment_zip: '',
      error_payment_cardnumber: false,
      error_payment_cvc: false,
      error_payment_expiry: false,
      error_payment_name: false,

      invalid_payment_cardnumber: false,
      invalid_payment_cvc: false,
      invalid_payment_expiry: false,
      invalid_payment_name: false,
    };
    this.fetch = this.fetch.bind(this);
    this.confirm = this.confirm.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.validateProfile = this.validateProfile.bind(this);
    this.backProfile = this.backProfile.bind(this);
    this.continueProfile = this.continueProfile.bind(this);

    this.validateBusiness = this.validateBusiness.bind(this);
    this.backBusiness = this.backBusiness.bind(this);
    this.continueBusiness = this.continueBusiness.bind(this);

    this.validatePayment = this.validatePayment.bind(this);
    this.backPayment = this.backPayment.bind(this);
    this.continuePayment = this.continuePayment.bind(this);
  };

  async componentDidMount() {
    await this.fetch();
  }

  fetch() {
    let endpoint = "api/Business/GetPricingPlan/" + this.props.match.params.id;
    apiClient.get(endpoint)
      .then(response => {
        this.setState({
          shortCode: response.data.shortCode,
          keyword: response.data.keyword,
          address: response.data.address,
          radius: response.data.radius,
          price: response.data.price,
          isNationWide: response.data.isNationWide,

          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
        });
      }).catch(error => {
        console.log(`Axios request failed: ${error}`);
      });
  }

  confirm() {
    this.setState({ isPricingPlan: false });
  };

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
    if (name === 'firstName')
      this.setState({ errorFirstName: value === "" });
    if (name === 'lastName')
      this.setState({ errorLastName: value === "" });
    if (name === 'email') {
      const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      const result = pattern.test(value);
      if (result === true) {
        this.setState({
          errorEmail: false,
        })
      } else {
        this.setState({
          errorEmail: true,
        })
      }
    }
    if (name === 'phoneNumber') {
      const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const result = pattern.test(value);
      this.setState({ errorPhoneNumber: !result });
    }
    if (name === 'password') {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!;@#$%^&*])(?=.{6,})/;
      const result = pattern.test(value);
      this.setState({ errorPassword: !result });
    }
    if (name === 'confirmPassword') {
      this.setState({ errorConfirmPassword: value !== this.state.password });
    }

    if (name === 'business_name')
      this.setState({ error_business_name: value === "" });
    if (name === 'business_phoneNumber') {
      const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const result = pattern.test(value);
      this.setState({ error_business_phoneNumber: !result });
    }
    if (name === 'business_address1')
      this.setState({ error_business_address1: value === "" });
    if (name === 'business_city')
      this.setState({ error_business_city: value === "" });
    if (name === 'business_state')
      this.setState({ error_business_state: value === "" });
    if (name === 'business_zip')
      this.setState({ error_business_zip: value === "" });

    if (name === 'payment_address')
      this.setState({ error_payment_address: value === "" });
    if (name === 'payment_city')
      this.setState({ error_payment_city: value === "" });
    if (name === 'payment_state')
      this.setState({ error_payment_state: value === "" });
    if (name === 'payment_zip')
      this.setState({ error_payment_zip: value === "" });

    if (name === 'payment_cardnumber') {
      this.setState({ error_payment_cardnumber: value === "" });
      this.setState({ invalid_payment_cardnumber: false })
    }
    if (name === 'payment_name') {
      this.setState({ error_payment_name: value === "" });
      this.setState({ invalid_payment_name: false })
    }
    if (name === 'payment_expiry') {
      this.setState({ error_payment_expiry: value === "" });
      this.setState({ invalid_payment_expiry: false })
    }
    if (name === 'payment_cvc') {
      this.setState({ error_payment_cvc: value === "" });
      this.setState({ invalid_payment_cvc: false })
    }
  }

  backProfile() {
    this.setState({ isPricingPlan: true });
  }

  async continueProfile() {
    await this.validateProfile();
    if (this.state.errorFirstName || this.state.errorLastName || this.state.errorEmail || this.state.errorPhoneNumber
      || this.state.errorPassword || this.state.errorConfirmPassword)
      return;
    this.setState({ activeTab: 2 });
  }

  validateProfile() {
    if (this.state.firstName.length === 0)
      this.setState({ errorFirstName: true })
    if (this.state.lastName.length === 0)
      this.setState({ errorLastName: true })
    if (this.state.email.length === 0)
      this.setState({ errorEmail: true })
    if (this.state.phoneNumber.length === 0)
      this.setState({ errorPhoneNumber: true })
    if (this.state.password.length === 0)
      this.setState({ errorPassword: true })
    if (this.state.confirmPassword.length === 0)
      this.setState({ errorConfirmPassword: true })
  }

  backBusiness() {
    this.setState({ activeTab: 1 });
  }

  async continueBusiness() {
    await this.validateBusiness();
    if (this.state.error_business_name || this.state.error_business_phoneNumber || this.state.error_business_address1 || this.state.error_business_city
      || this.state.error_business_state || this.state.error_business_zip)
      return;
    this.setState({ activeTab: 3 });
  }

  validateBusiness() {
    if (this.state.business_name.length === 0)
      this.setState({ error_business_name: true })
    if (this.state.business_phoneNumber.length === 0)
      this.setState({ error_business_phoneNumber: true })
    if (this.state.business_address1.length === 0)
      this.setState({ error_business_address1: true })
    if (this.state.business_city.length === 0)
      this.setState({ error_business_city: true })
    if (this.state.business_state.length === 0)
      this.setState({ error_business_state: true })
    if (this.state.business_zip.length === 0)
      this.setState({ error_business_zip: true })
  }

  backPayment() {
    this.setState({ activeTab: 2 });
  }

  async continuePayment(event) {
    await this.validatePayment();
    if (this.state.error_payment_address || this.state.error_payment_city || this.state.error_payment_state || this.state.error_payment_zip ||
      this.state.invalid_payment_cardnumber || this.state.invalid_payment_city || this.state.invalid_payment_state || this.state.invalid_payment_zip
    )
      return;
    this.submitForm(event);
  }

  validatePayment() {
    if (this.state.payment_address.length === 0)
      this.setState({ error_payment_address: true })
    if (this.state.payment_city.length === 0)
      this.setState({ error_payment_city: true })
    if (this.state.payment_state.length === 0)
      this.setState({ error_payment_state: true })
    if (this.state.payment_zip.length === 0)
      this.setState({ error_payment_zip: true })

    if (this.state.payment_cardnumber.length === 0) {
      this.setState({ error_payment_cardnumber: true })
    } else if ((" " + document.getElementsByName("payment_cardnumber")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
      this.setState({ invalid_payment_cardnumber: true })
    }
    if (this.state.payment_name.length === 0) {
      this.setState({ error_payment_name: true })
    } else if ((" " + document.getElementsByName("payment_name")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
      this.setState({ invalid_payment_name: true })
    }
    if (this.state.payment_expiry.length === 0) {
      this.setState({ error_payment_expiry: true })
    } else if ((" " + document.getElementsByName("payment_expiry")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
      this.setState({ invalid_payment_expiry: true })
    }

    if (this.state.payment_cvc.length === 0) {
      this.setState({ error_payment_cvc: true })
    } else if ((" " + document.getElementsByName("payment_cvc")[0].className + " ").replace(/[\n\t]/g, " ").indexOf(" invalid-input ") > -1) {
      this.setState({ invalid_payment_cvc: true })
    }
  }

  submitForm(event) {
    event.preventDefault();
    Swal.fire({
      title: 'Processing',
      html: 'We are processing your request...',
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    });
    let endpoint = "api/Account/Register";
    apiClient.post(endpoint, {
      "itemId": this.props.match.params.id,
      "account": {
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "email": this.state.email,
        "password": this.state.password
      },
      "business": {
        "name": this.state.business_name,
        "phoneNumber": this.state.business_phoneNumber,
        "address1": this.state.business_address1,
        "address2": this.state.business_address2,
        "city": this.state.business_city,
        "state": this.state.business_state,
        "zip": this.state.business_zip
      },
      "creditCard": {
        "cardNumber": this.state.payment_cardnumber,
        "expirationDate": this.state.payment_expiry,
        "cvCode": this.state.payment_cvc,
        "firstName": this.state.payment_name,
        "lastName": '',
        "address": this.state.payment_address,
        "city": this.state.payment_city,
        "state": this.state.payment_state,
        "zip": this.state.payment_zip,
        "email": this.state.email
      }
    }).then(response => {
      Swal.fire({
        type: 'success',
        title: 'Success',
        text: 'Your request has been completed.',
      }).then(() => {
        this.props.history.push('/login');
      });
    }).catch(error => {
      NotificationManager.error(error.response.data.errors[""][0], 'Error');
      Swal.close();
    });
  }

  render() {
    let view;
    if (this.state.isPricingPlan)
      view = this.render_pricingplan();
    else
      view = this.render_register();
    return (
      <div>
        <div className="bg-image">
          {view}
          <footer className="footer-area-account">
            <Footer />
          </footer>
        </div>
      </div>
    );
  };

  render_pricingplan() {
    return (
      <div id="view_pricingplan">
        <div className="kt-header--fixed kt-header-mobile--fixed kt-aside--enabled kt-aside--fixed kt-page--loading">
          <div className="kt-grid kt-grid--ver kt-grid--root">
            <div className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--signin kt-register" id="kt_pricingplan">
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 kt-login__container wh-container pricing-plan_container">
                        <div className="pricing_plan-header text-center">
                          <h2 className="pricing_plan-title roboto-slab font-weight-bold">Your Pricing Plan</h2>
                          <div className="pricing_plan-title_desc">Please review the prices for your keyword request.</div>
                        </div>
                        <div className="pricing_plan-body">
                          <div className="pricing_plan-shortCode">
                            <div className="container">
                              <div className="row">
                                <div className="col-5 pricing_plan-shortCode_title font-weight-bold ">Shortcode :</div>
                                <div className="col-7 pricing_plan-shortCode_desc font-weight-bold text-uppercase">{this.state.shortCode}</div>
                              </div>
                            </div>
                          </div>
                          <div className="pricing_plan-shortCode">
                            <div className="container">
                              <div className="row">
                                <div className="col-5 pricing_plan-shortCode_title font-weight-bold">keyword :</div>
                                <div className="col-7 pricing_plan-shortCode_desc font-weight-bold">{this.state.keyword}</div>
                              </div>
                            </div>
                          </div>
                          {this.state.isNationWide ?
                            <>
                              <div className="pricing_plan-shortCode">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-5 pricing_plan-shortCode_title font-weight-bold">Nationwide :</div>
                                    <div className="col-7 pricing_plan-shortCode_desc font-weight-normal"><i className="fa fa-check" aria-hidden="true" style={{ color: '#29b92d' }}></i></div>
                                  </div>
                                </div>
                              </div>
                            </>
                            : <>
                              <div className="pricing_plan-shortCode">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-5 pricing_plan-shortCode_title font-weight-bold">Address :</div>
                                    <div className="col-7 pricing_plan-shortCode_desc font-weight-normal">{this.state.address}</div>
                                  </div>
                                </div>
                              </div>
                              <div className="pricing_plan-shortCode">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-5 pricing_plan-shortCode_title font-weight-bold">radius :</div>
                                    <div className="col-7 pricing_plan-shortCode_desc font-weight-normal">{this.state.radius} miles</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          <div className="pricing_plan-shortCode">
                            <div className="container">
                              <div className="row">
                                <div className="col-5 pricing_plan-shortCode_title pricing_plan-color font-weight-bold">Total :</div>
                                <div className="col-7 pricing_plan-shortCode_desc pricing_plan-color font-weight-bold">${this.state.price} per month</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pricing_plan-footer">
                          <div className="pricing_plan-register">
                            <button type="button" className="btn btn-pill bttn-success btn-shadow large" id="btn_pp_confirm" onClick={this.confirm}>Confirm & Register</button>
                          </div>
                          <div className="pricing_plan-terms">By clicking Confirm & Register, you agree to the <a href={null}>terms </a>  and <a href={null}>privacy policy.</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render_register() {
    let classNameProfileLink = "nav-link " + (this.state.activeTab === 1 ? "active" : "disabled");
    let classNameBusinessLink = "nav-link " + (this.state.activeTab === 2 ? "active" : "disabled");
    let classNamePaymentLink = "nav-link " + (this.state.activeTab === 3 ? "active" : "disabled");

    let classNameProfileTab = "tab-pane fade " + (this.state.activeTab === 1 ? "show active" : "");
    let classNameBusinessTab = "tab-pane fade " + (this.state.activeTab === 2 ? "show active" : "");
    let classNamePaymentTab = "tab-pane fade " + (this.state.activeTab === 3 ? "show active" : "");
    return (
      <div className="container register-wrapper">
        <div className="row">
          <div className="profile-container">
            <div className="col-md-12 row m-0 p-0">
              <div className="nav flex-column col-lg-5 p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <div className="little-description">
                  <h3 className="roboto-slab font-weight-bold">Register your Account</h3>
                  <p className="font-weight-normal mt-3">Enter your profile and payment information to create an account.</p>
                </div>
                <a className={classNameProfileLink} id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">Profile Information</a>
                <a className={classNameBusinessLink} id="v-pills-business-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-business" aria-selected="false">Business Information</a>
                <a className={classNamePaymentLink} id="v-pills-payment-tab" data-toggle="pill" href="#v-pills-payment" role="tab" aria-controls="v-pills-payment" aria-selected="false">Payment Information</a>
              </div>
              <div className="tab-content col-lg-7" id="v-pills-tabContent">
                <div className={classNameProfileTab} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <div className="mb-4">
                    <h3 className="roboto-slab font-weight-bold">Profile Information</h3>
                    <p className="font-weight-normal mt-3">Enter your profile information.</p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="kt-portlet__body profile-info">
                        <div className=""></div>
                        <div className="row">
                          <div className="col-md-6 tl-black profile-form-input">
                            <FormInput value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" required={true} label="First Name" className="form-control" />
                            {this.state.errorFirstName ? <div className="error">Required</div> : ''}
                          </div>
                          <div className="col-md-6 tl-black profile-form-input">
                            <FormInput value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" required={true} label="Last Name" className="form-control" />
                            {this.state.errorLastName ? <div className="error">Required</div> : ''}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 tl-black profile-form-input">
                            <FormInput value={this.state.email} onChange={this.handleChange} type="email" name="email" required={true} label="Email/Username" className="form-control" />
                            {this.state.errorEmail ? <div className="error">Valid email is required</div> : ''}
                          </div>
                          <div className="col-md-6 tl-black profile-form-input">
                            <label>Phone Number *</label>
                            <InputMask value={this.state.phoneNumber} onChange={this.handleChange} name="phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                            {this.state.errorPhoneNumber ? <div className="error">Please enter your phone number</div> : ''}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 tl-black profile-form-input">
                            <FormInput value={this.state.password} onChange={this.handleChange} type="password" name="password" required={true} label="Password" className="form-control" />
                            {this.state.errorPassword ? <div className="error">At least 6 characters and contain at the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (e.g. !@#$%^&*)</div> : ''}
                          </div>
                          <div className="col-md-6 tl-black profile-form-input">
                            <FormInput value={this.state.confirmPassword} onChange={this.handleChange} type="password" name="confirmPassword" required={true} label="Confirm Password" className="form-control" />
                            {this.state.errorConfirmPassword ? <div className="error">Password not match</div> : ''}
                          </div>
                        </div>
                      </div>
                      <div className="kt-portlet__foot mt-5 pt-5">
                        <div className="kt-form__actions row">
                          <div className="form-group col-sm-6 col-12">
                            <button className="btn btn-block btn-pill btn-light-grey btn-width btn-shadow" onClick={this.backProfile}>Back</button>
                          </div>
                          <div className="form-group col-sm-6 col-12">
                            <button className="btn btn-block btn-pill bttn-success btn-width btn-shadow float-right" onClick={this.continueProfile}>Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNameBusinessTab} id="v-pills-business" role="tabpanel" aria-labelledby="v-pills-business-tab">
                  <div className="mb-4">
                    <h3 className="font-weight-bold roboto-slab">Business information</h3>
                    <p className="font-weight-normal mt-3">Enter your business details</p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-7 business-form-input">
                          <FormInput value={this.state.business_name} onChange={this.handleChange} type="text" name="business_name" required={true} label="Business Name" className="form-control" />
                          {this.state.error_business_name ? <div className="error">Required</div> : ''}
                        </div>
                        <div className="col-md-5 business-form-input">
                          <label>Phone Number *</label>
                          <InputMask value={this.state.business_phoneNumber} onChange={this.handleChange} name="business_phoneNumber" placeholder=" " className="form-control custom-landing-form user-form" mask="(999) 999-9999" maskChar=" " />
                          {this.state.error_business_phoneNumber ? <div className="error">Valid phone number is required</div> : ''}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-7 business-form-input">
                          <FormInput value={this.state.business_address1} onChange={this.handleChange} type="text" name="business_address1" required={true} label="Business Address" className="form-control" />
                          {this.state.error_business_address1 ? <div className="error">Required</div> : ''}
                        </div>
                        <div className="col-md-5 business-form-input">
                          <FormInput value={this.state.business_address2} onChange={this.handleChange} type="text" name="business_address2" label="Suite" className="form-control" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 business-form-input">
                          <FormInput value={this.state.business_city} onChange={this.handleChange} type="text" name="business_city" required={true} label="City" className="form-control" />
                          {this.state.error_business_city ? <div className="error">Required</div> : ''}
                        </div>
                        <div className="col-md-4 business-form-input">
                          <FormInput value={this.state.business_state} onChange={this.handleChange} type="text" name="business_state" required={true} label="State" className="form-control" />
                          {this.state.error_business_state ? <div className="error">Required</div> : ''}
                        </div>
                        <div className="col-md-4 business-form-input">
                          <FormInput value={this.state.business_zip} onChange={this.handleChange} type="text" name="business_zip" required={true} label="Zip Code" className="form-control" />
                          {this.state.error_business_zip ? <div className="error">Required</div> : ''}
                        </div>
                      </div>
                      <div className="kt-portlet__foot">
                        <div className="kt-form__actions row">
                          <div className="form-group col-sm-6 col-12">
                            <button className="btn btn-block btn-light-grey btn-shadow btn-pill btn-width" onClick={this.backBusiness}>Back</button>
                          </div>
                          <div className="form-group col-sm-6 col-12">
                            <button className="btn btn-block btn-pill bttn-success btn-width btn-shadow float-right" onClick={this.continueBusiness}>Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNamePaymentTab} id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                  <div className="mb-4">
                    <h3 className="font-weight-bold roboto-slab">Payment Information</h3>
                    <p className="font-weight-normal mt-3">Enter your payment information</p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="kt-portlet__body">
                        <div className="row">
                          <div className="col-md-12">
                            <div id="card-wrapper" className="mb-4"></div>
                          </div>
                          <div className="col-md-12">
                            <CardReactFormContainer
                              container="card-wrapper"
                              formInputsNames={
                                {
                                  number: 'payment_cardnumber', // optional — default "number"
                                  expiry: 'payment_expiry',// optional — default "expiry"
                                  cvc: 'payment_cvc', // optional — default "cvc"
                                  name: 'payment_name' // optional - default "name"
                                }
                              }
                              classes={
                                {
                                  valid: 'valid-input', // optional — default 'jp-card-valid'
                                  invalid: 'invalid-input' // optional — default 'jp-card-invalid'
                                }
                              }
                              formatting={true}
                            >
                              <div className="row payment-form-group">
                                <div className="col-md-6 payment-form-input">
                                  <div className="form-group custom-landing-form">
                                    <label htmlFor="payment_cardnumber">Card Number</label><span>*</span><br></br>
                                    <input type="text" name="payment_cardnumber" className="form-control" onChange={this.handleChange} />
                                  </div>
                                  {this.state.error_payment_cardnumber ? <div className="error">Required</div> : ''}
                                  {this.state.invalid_payment_cardnumber ? <div className="error-invalid">Invalid</div> : ''}
                                </div>
                                <div className="col-md-6 payment-form-input">
                                  <div className="form-group custom-landing-form">
                                    <label htmlFor="payment_name">Name on Card</label><span>*</span><br></br>
                                    <input type="text" name="payment_name" className="form-control" onChange={this.handleChange} />
                                  </div>
                                  {this.state.error_payment_name ? <div className="error">Required</div> : ''}
                                  {this.state.invalid_payment_name ? <div className="error-invalid">Invalid</div> : ''}
                                </div>
                                <div className="col-md-6 payment-form-input">
                                  <div className="form-group custom-landing-form">
                                    <label htmlFor="payment_expiry">Expiration Date</label><span>*</span><br></br>
                                    <input type="text" name="payment_expiry" className="form-control" onChange={this.handleChange} />
                                  </div>
                                  {this.state.error_payment_expiry ? <div className="error">Required</div> : ''}
                                  {this.state.invalid_payment_expiry ? <div className="error-invalid">Invalid</div> : ''}
                                </div>
                                <div className="col-md-6 payment-form-input">
                                  <div className="form-group custom-landing-form">
                                    <label htmlFor="payment_cvc">Security Code (CVC)</label><span>*</span><br></br>
                                    <input type="text" name="payment_cvc" className="form-control" value={this.state.payment_cvc} onChange={this.handleChange} />
                                  </div>
                                  {this.state.error_payment_cvc ? <div className="error">Required</div> : ''}
                                  {this.state.invalid_payment_cvc ? <div className="error-invalid">Invalid</div> : ''}
                                </div>
                              </div>
                            </CardReactFormContainer>
                          </div>

                        </div>
                        <div className="sub-headline">
                          <h4>Billing Address</h4>
                        </div>
                        <div className="row payment-form-group">
                          <div className="col-md-12 payment-form-input">
                            <FormInput value={this.state.payment_address} onChange={this.handleChange} type="text" name="payment_address" required={true} label="Address" className="form-control" />
                            {this.state.error_payment_address ? <div className="error">Required</div> : ''}
                          </div>
                          <div className="col-md-6 payment-form-input">
                            <FormInput value={this.state.payment_city} onChange={this.handleChange} type="text" name="payment_city" required={true} label="City" className="form-control" />
                            {this.state.error_payment_city ? <div className="error">Required</div> : ''}
                          </div>
                          <div className="col-md-3 payment-form-input">
                            <FormInput value={this.state.payment_state} onChange={this.handleChange} type="text" name="payment_state" required={true} label="State" className="form-control" />
                            {this.state.error_payment_state ? <div className="error">Required</div> : ''}
                          </div>
                          <div className="col-md-3 payment-form-input">
                            <FormInput value={this.state.payment_zip} onChange={this.handleChange} type="text" name="payment_zip" required={true} label="Zip Code" className="form-control" />
                            {this.state.error_payment_zip ? <div className="error">Required</div> : ''}
                          </div>
                        </div>
                        <div className="kt-portlet__foot">
                          <div className="kt-form__actions row">
                            <div className="form-group col-sm-6 col-12">
                              <button className="btn btn-block btn-pill btn-light-grey btn-width btn-shadow" onClick={this.backPayment}>Back</button>
                            </div>
                            <div className="form-group col-sm-6 col-12">
                              <button className="btn btn-block btn-pill bttn-success btn-shadow float-right" onClick={this.continuePayment}>Complete Registration</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div >
    );
  }
}

export default Profile;