import React, { Component } from 'react';

class FormInput extends Component {
    render() {
        const { name, placeholder, id, minlength, maxlength, value, onChange, onClick, label, style, className, type, required } = this.props;
        return (
            <div className="form-group custom-landing-form">
                <label htmlFor={name}> {label} </label>{required? <span>*</span> : ''}
                <input style={style} maxLength={maxlength} minLength={minlength} value={value} id={id} onClick={onClick} onChange={onChange} name={name} label={label} placeholder={placeholder} required={required} className={className} type={type} />
            </div>
        );
    }
}

export default FormInput;