import React, { Component } from "react";
import AsideMenuItem from "./AsideMenuItem";
import AsideMenuSubItem from "./AsideMenuSubItem";
import LogoImg from "../../../assets/images/textlegal.png";

class AsideMenu extends Component {
  constructor(props) {
    require("./menu.scss");
    super(props);
    this.state = {
      admin: localStorage.getItem("roles").includes("Admin"),
      attorney: localStorage.getItem("roles").includes("Attorney"),
      staff: localStorage.getItem("roles").includes("Staff"),
    };
  }

  render() {
    return (
      <div>
        <button className="kt-aside-close " id="kt_aside_close_btn">
          <i className="la la-close"></i>
        </button>
        <div id="kt_aside" className="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop">
          {/* Brand stuff */}
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo" style={{ marginTop: "35px" }}>
              <a href="/" className="aside-menu-title">
                <img src={LogoImg} alt="signIn logo" style={{ width: "80px" }} />
              </a>
            </div>
          </div>
          {/* END Brand stuff */}
          <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
            <div id="kt_aside_menu" className="kt-aside-menu" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
              <nav>
                <ul className="kt-menu__nav ">
                  {(this.state.attorney || this.state.staff) && <AsideMenuItem className="active" title="SMS/MMS" link="/messaging" />}
                  {this.state.attorney && <AsideMenuItem title="Packages" link="/items" />}
                  {this.state.attorney && <AsideMenuItem title="Report" link="/reports" />}
                  {this.state.attorney && <AsideMenuItem title="Users" link="/users" />}
                  {this.state.admin && (
                    <AsideMenuSubItem title="ADMIN PORTAL">
                      <AsideMenuItem title="Packages" link="/items" />
                      <AsideMenuItem title="Users" link="/admin/users" />
                      <AsideMenuItem title="Companies" link="/admin/businesses" />
                      <AsideMenuItem title="Report" link="/admin/reports" />
                      <AsideMenuItem title="Payment" link="/admin/payments" />
                      <AsideMenuItem title="Configuration" link="/config" />
                      <AsideMenuItem title="Data Types" link="/datatypes" />
                    </AsideMenuSubItem>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AsideMenu;
