import React, { Component } from "react";
import path from 'path';
import { MobileHeader, AsideMenu, TopMenu } from '../components';

export default class ApplicationLayout extends Component {
  constructor(props) {
    super(props);
    require('../assets/appside/css/fontawesome.5.7.2.css');
    require('../assets/metronic');
    require('../site.scss');
    //require('./reactTable.scss');
    this.state = {
      shortcodes: [],
      keywords: [],
      selectedShortcode: "",
      selectedKeywords: [],
      address: "",
      latitude: null,
      longitude: null,
      distance: "",
      isLoading: true,
      errors: []
    };
  }

  componentDidMount() {
    //init styles
    let style = document.createElement("link");
    style.rel = "shortcut icon";
    style.type = "image/x-icon";
    style.href = path.resolve(process.env.PUBLIC_URL, "img/favicon.ico");
    document.head.appendChild(style);

    style = document.createElement("link");
    style.href = "https://fonts.googleapis.com/css?family=Comfortaa&display=swap";
    style.rel = "stylesheet";
    document.head.appendChild(style);

    style = document.createElement("link");
    style.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
    style.rel = "stylesheet";
    document.head.appendChild(style);

    // init scripts
    let script = document.createElement("script");
    script.src = path.resolve(process.env.PUBLIC_URL, "js/vendors.bundle.js");
    document.body.appendChild(script);

    script = document.createElement("script");
    script.innerText = ' var KTAppOptions = { "colors": { "state": { "brand": "#5d78ff", "dark": "#282a3c", "light": "#ffffff", "primary": "#5867dd", "success": "#34bfa3", "info": "#36a3f7", "warning": "#ffb822", "danger": "#fd3995" }, "base": { "label": ["#c5cbe3", "#a1a8c3", "#3d4465", "#3e4466"], "shape": ["#f0f3ff", "#d9dffa", "#afb4d4", "#646c9a"] } } };';
    document.body.appendChild(script);
  }

  render() {
    if (this.props.withoutMenu || localStorage.getItem("isAuthenticated") === "false") {
      return <div>
        {this.props.children}
      </div>;
    } else {
      return <div>
        <MobileHeader />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="t-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <AsideMenu />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper kt-height" id="kt_wrapper">
              <TopMenu />
              <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" id="kt_content">
                {this.props.children}
              </div>
              <div className="footer"></div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}