import React from 'react';
import { Link } from "react-router-dom";
import LogoImg from '../../assets/images/commentArtboard 1@1.5x.png'

export const Logo = () => (
  <div className="page-logo">
    <Link to="/">
      <img src={LogoImg} alt="logo" className="logo-default" style={{ maxWidth: "280px", width: '100%' }} />
    </Link>
    <div className="menu-toggler sidebar-toggler">
      <span></span>
    </div>
  </div >
)

export default Logo;