import React, { Component } from 'react'
import apiClient from '../../components/Core/apiClient'
import axios from 'axios'
import MapComponent from '../../components/Map/map'
import Swal from 'sweetalert2'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import MessageTree from '../../components/MessageTree/MessageTree'

class RequestEdit extends Component {
  constructor(props) {
    super(props)
    require('react-table/react-table.css')
    require('react-notifications/lib/notifications.css')
    require('../../containers/reactTable.scss')
    require('./Item.scss')
    this.state = {
      id: this.props.match.params.id,

      status: '',
      name: '',
      phoneNumber: '',
      email: '',
      shortCode: '',
      businessName: '',
      price: 0,

      address: '',
      latitude: null,
      longitude: null,
      isNationWide: true,

      keyword: '',
      zips: [],
      availableKeywords: [],
      selectedKeywords: [],
      polygons: [],
      loading: true,
      errorPrice: false,

      isApproved: false,
      dataExists: false,

      isAdmin: localStorage.getItem('roles').includes('Admin'),
    }
    this.fetch = this.fetch.bind(this)
    this.searchZipCode = this.searchZipCode.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.approve = this.approve.bind(this)
    this.decline = this.decline.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  formatPhoneNumber(phoneNumber) {
    if (phoneNumber) return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    else return '-'
  }

  searchZipCode(radius, latitude, longitude) {
    axios({
      url: 'https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary/zipcode/location',
      params: { latitude: latitude, longitude: longitude, radius: radius },
      method: 'get',
      headers: {
        'X-RapidAPI-Host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
        'X-RapidAPI-Key': '50dd6f6f04msh065ffab2e0fd2d5p1a86adjsnb1bd857ca0ab',
      },
    })
      .then((response) => {
        let polygons = []
        response.data.features.map((region) => {
          return region?.geometry?.coordinates?.map((coord, i) => {
            let coordArr = []
            if (coord.length === 1) coord[0].map((coodinate) => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
            else coord.map((coodinate) => coordArr.push({ lat: coodinate[1], lng: coodinate[0] }))
            polygons.push({
              key: region.properties.zipCode + '_' + i,
              coords: coordArr,
              used: false,
            })
            return coordArr
          })
        })
        this.setState({ polygons: polygons })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async fetch() {
    let endpoint = '/api/Business/GetItemDetail/' + this.state.id
    this.setState({
      loading: true,
    })
    await apiClient
      .get(endpoint)
      .then(async (response) => {
        if (response.data) {
          await this.setState({
            loading: false,
            shortCode: response.data.shortCode,
            keyword: response.data.keyword,
            isNationWide: response.data.isNationWide,
            price: response.data.price,
            businessName: response.data.businessName,
            status: response.data.status,
            address: response.data.address,
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            radius: response.data.radius,
            zips: response.data.zips,

            phoneNumber: response.data.contactInfo.phoneNumber,
            name: response.data.contactInfo.name,
            email: response.data.contactInfo.email,

            isApproved: response.data.status === 'Approved' || response.data.status === 'Active',
          })
          if (!response.data.isNationWide) this.searchZipCode(this.state.radius, this.state.latitude, this.state.longitude)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false,
        })
        console.log(`Axios request failed: ${error}`)
      })
  }

  handleInputChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
      errorPrice: value <= 0,
    })
  }

  validate() {
    this.setState({
      errorPrice: this.state.price <= 0,
    })
  }

  async approve(event) {
    await this.validate()
    if (this.state.errorPrice) return
    event.preventDefault()
    Swal.fire({
      title: 'Processing',
      html: 'We are processing your request...',
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    await apiClient
      .post('api/Business/ApproveItem', {
        id: this.state.id,
        price: this.state.price,
      })
      .then(async (response) => {
        NotificationManager.success('Request has been successfully updated!', 'Success')
        Swal.close()
        await this.setState({
          status: 'Approved',
          isApproved: true,
        })
      })
      .catch((error) => {
        NotificationManager.error(`${error}`, 'Error')
        console.log(`Axios request failed: ${error}`)
        Swal.close()
      })
  }

  decline() {
    Swal.fire({
      text: 'Are you sure that you want to delete this request?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#c9d1d8',
      confirmButtonColor: '#57b848',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        let endpoint = 'api/Business/DeclineItem/' + this.state.id
        apiClient
          .delete(endpoint)
          .then((response) => {
            NotificationManager.success('Request has been successfully deleted!', 'Success')
            this.props.history.push('/items')
          })
          .catch((error) => {
            NotificationManager.error(`${error}`, 'Error')
            console.log(`Axios request failed: ${error}`)
            Swal.close()
          })
      }
    })
  }

  render() {
    if (this.state.loading) {
      return <div></div>
    } else {
      if (this.state.isNationWide) {
        return (
          <>
            <div className="packages-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 kt-item">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Business: </span>
                              {this.state.businessName ? <span className="desc_secondary">{this.state.businessName}</span> : <span className="desc_secondary">-</span>}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="detail_group float-right">
                            <label className="title">
                              <span className="roboto-slab desc-title">Status: </span>
                              <span className="desc_secondary"> {this.state.status}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Short Code: </span>
                              <span className="desc_secondary">{this.state.shortCode}</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="detail_group float-right">
                            <label className="title">
                              <span className="roboto-slab desc-title">Nationwide: </span>
                              <span className="desc_secondary"> {this.state.isNationWide ? <i className="fa fa-check" aria-hidden="true" style={{ color: '#29b92d' }}></i> : ''}</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Keyword: </span>
                              <span className="desc_secondary">{this.state.keyword}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.isApproved ? (
                      <div className="col-md-12">
                        <div className="detail_group">
                          <label className="title">
                            <span className="roboto-slab desc-title">Price: </span>
                            <span className="desc_secondary">${this.state.price}</span>
                          </label>
                        </div>
                      </div>
                    ) : this.state.isAdmin ? (
                      <div className="col-md-12">
                        <div className="detail_group">
                          <label className="title">
                            <span className="roboto-slab desc-title">
                              Price: <span className="desc_required">*</span>
                            </span>
                            <span className="desc_secondary">
                              <div className="input-group">
                                <span className="input-group-addon">$</span>
                                <input type="number" name="price" id="price" className="desc_price" value={this.state.price} onChange={this.handleInputChange}></input>
                              </div>
                            </span>
                            <div>{this.state.errorPrice ? <i className="error-content">This field is required!</i> : ''}</div>
                          </label>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!this.state.isApproved && this.state.isAdmin ? (
                      <>
                        <hr />
                        <div className="col-md-12">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Name: </span>
                              <span className="desc_secondary">{this.state.name}</span>
                            </label>
                          </div>
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Email: </span>
                              <span className="desc_secondary">{this.state.email}</span>
                            </label>
                          </div>
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Phone Number: </span>
                              <span className="desc_secondary">{this.formatPhoneNumber(this.state.phoneNumber)}</span>
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    {!this.state.isApproved && this.state.isAdmin ? (
                      <>
                        <hr />
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6" onClick={this.decline}>
                              <button className="btn btn-pill btn-width btn-light-grey btn-shadow req-btn">Decline</button>
                            </div>
                            <div className="col-md-6" onClick={this.approve}>
                              <button className="btn btn-width float-right btn-shadow button btn-pill btn-normal req-btn">Approve</button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-md-12 message-tree">
                    <MessageTree codeId={this.state.id} approved={this.state.isApproved} />
                  </div>
                </div>
              </div>
              <NotificationContainer />
            </div>
          </>
        )
      } else {
        if (!this.state.polygons.length) return null
        return (
          <>
            <div className="packages-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 kt-item">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Business: </span>
                              {this.state.businessName ? <span className="desc_secondary">{this.state.businessName}</span> : <span className="desc_secondary">-</span>}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="detail_group float-right">
                            <label className="title">
                              <span className="roboto-slab desc-title">Status: </span>
                              <span className="desc_secondary"> {this.state.status}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Short Code: </span>
                              <span className="desc_secondary">{this.state.shortCode}</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Keyword: </span>
                              <span className="desc_secondary">{this.state.keyword}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.isApproved ? (
                      <div className="col-md-12">
                        <div className="detail_group">
                          <label className="title">
                            <span className="roboto-slab desc-title">Price: </span>
                            <span className="desc_secondary">${this.state.price}</span>
                          </label>
                        </div>
                      </div>
                    ) : this.state.isAdmin ? (
                      <div className="col-md-12">
                        <div className="detail_group">
                          <label className="title">
                            <span className="roboto-slab desc-title">
                              Price: <span className="desc_required">*</span>
                            </span>
                            <span className="desc_secondary">
                              <div className="input-group">
                                <span className="input-group-addon">$</span>
                                <input type="number" name="price" id="price" className="desc_price" value={this.state.price} onChange={this.handleInputChange}></input>
                              </div>
                            </span>
                            <div>{this.state.errorPrice ? <i className="error-content">This field is required!</i> : ''}</div>
                          </label>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!this.state.isApproved && this.state.isAdmin ? (
                      <>
                        <hr />
                        <div className="col-md-12">
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Name: </span>
                              <span className="desc_secondary">{this.state.name}</span>
                            </label>
                          </div>
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Email: </span>
                              <span className="desc_secondary">{this.state.email}</span>
                            </label>
                          </div>
                          <div className="detail_group">
                            <label className="title">
                              <span className="roboto-slab desc-title">Phone Number: </span>
                              <span className="desc_secondary">{this.formatPhoneNumber(this.state.phoneNumber)}</span>
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <hr />
                    <div className="col-md-12">
                      <div className="detail_group">
                        <label className="title">
                          <span className="roboto-slab desc-title">Address: </span>
                          <span className="desc_secondary">{this.state.address}</span>
                        </label>
                      </div>
                      <div className="detail_group">
                        <label className="title">
                          <span className="roboto-slab desc-title">Radius: </span>
                          <span className="desc_secondary">
                            {this.state.radius}
                            {this.state.radius > 1 ? ' miles' : this.state.radius === 1 ? ' mile' : ''}
                          </span>
                        </label>
                      </div>
                      <div className="detail_group">
                        <label className="title">
                          <span className="roboto-slab desc-title">Zip Code(s): </span>
                        </label>
                        <div className="desc_secondary zipcode-tag-wrapper">
                          {this.state.zips.map((element, idx) => {
                            return (
                              <span className="zipcode-tag" key={idx}>
                                {element}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    {!this.state.isApproved && this.state.isAdmin ? (
                      <>
                        <hr />
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-6" onClick={this.decline}>
                              <button className="btn btn-pill btn-width btn-light-grey btn-shadow req-btn">Decline</button>
                            </div>
                            <div className="col-6" onClick={this.approve}>
                              <button className="btn btn-width btn-shadow float-right button btn-pill btn-normal req-btn">Approve</button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-md-6 kt-map">
                    <MapComponent address={this.state.address} center={{ lat: +this.state.latitude, lng: +this.state.longitude }} polygons={this.state.polygons} radius={+this.state.radius} />
                  </div>
                  <div className="col-md-12 message-tree">
                    <MessageTree codeId={this.state.id} approved={this.state.isApproved} />
                  </div>
                </div>
              </div>
              <NotificationContainer />
            </div>
          </>
        )
      }
    }
  }
}

export default RequestEdit
