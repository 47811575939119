import axios from 'axios'
import querystring from 'querystring'
import httpStatus from 'http-status-codes'
import worker_script from './worker'
import Cookies from 'universal-cookie'

var auth = {
  client_id: 'web',
  client_secret: 'd596c820-c4e1-4ebd-a63a-5d3b125a5a2b',
  url: 'oauth/token',
}

var uuid = require('uuid')

const getClient = (token = false) => {
  const options = {
    baseURL: 'https://textlegal-backend.azurewebsites.net/',
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': token ? 'application/x-www-form-urlencoded' : 'application/json',
    validateStatus: function (status) {
      return status >= httpStatus.OK && status <= httpStatus.MULTI_STATUS
    },
  }

  options.headers = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': token ? 'application/x-www-form-urlencoded' : 'application/json',
  }

  return axios.create(options)
}

export default {
  get(url) {
    return getClient()
      .get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  post(url, data = {}) {
    return getClient()
      .post(url, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  delete(url) {
    return getClient()
      .delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },

  signIn(email, password) {
    let cookies = new Cookies()
    let device_id = cookies.get('deviceId')
    if (device_id === undefined) {
      device_id = uuid.v4()
      cookies.set('deviceId', device_id)
    }
    return getClient(true)
      .post(
        auth.url,
        querystring.stringify({
          grant_type: 'password',
          username: email,
          password: password,
          scope: 'offline_access',
          client_id: auth.client_id,
          client_secret: auth.client_secret,
          device_id: device_id,
        }),
      )
      .then((response) => {
        localStorage.setItem('roles', response.data.roles)
        localStorage.setItem('profileFirstName', response.data.firstName)
        localStorage.setItem('profileLastName', response.data.lastName)
        localStorage.setItem('access_token', response.data.access_token)
        let date = new Date()
        date.setHours(date.getHours(), date.getMinutes(), date.getSeconds() + response.data.expires_in)
        localStorage.setItem('expire_date', date.getTime())
        localStorage.setItem('refresh_token', response.data.refresh_token)
        localStorage.setItem('isAuthenticated', true)
      })
      .catch((error) => Promise.reject(error))
  },
}

var myWorker = new Worker(worker_script)

myWorker.onmessage = (m) => {
  //console.log("refreshToken start");
  if (localStorage.getItem('isAuthenticated') === 'true') {
    if (new Date().getTime() > +localStorage.getItem('expire_date')) {
      getClient(true)
        .post(
          auth.url,
          querystring.stringify({
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('refresh_token'),
            client_id: auth.client_id,
            client_secret: auth.client_secret,
          }),
        )
        .then((response) => {
          localStorage.setItem('access_token', response.data.access_token)
          let date = new Date()
          date.setHours(date.getHours(), date.getMinutes(), date.getSeconds() + response.data.expires_in)
          localStorage.setItem('expire_date', date.getTime())
          localStorage.setItem('isAuthenticated', true)
          console.log('refreshToken ok')
        })
        .catch((error) => {
          localStorage.setItem('isAuthenticated', false)
          console.log('refreshToken error: ' + error)
        })
    }
  }
}
myWorker.postMessage('im from main')
