import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
// import apiClient from '../../components/Core/apiClient';

class WinPage extends Component {
  constructor(props) {
    super(props);
    require("./elkandelk.scss");
    this.state = {
      isSending: false,
      startDate: new Date()
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleSubmit = event => {
  //   event.preventDefault();
  //   var name = document.getElementById('name').value;
  //   var address = document.getElementById('address').value;
  //   var phone = document.getElementById('phone').value;
  //   var email = document.getElementById('email').value;
  //   var desc = document.getElementById('injury-desc').value;

  //   var messageBody =
  //     "<br /><br />Address: " + address + '<br />' +
  //     "Phone Number: " + phone + '<br />' +
  //     "Injury Type: " + typeStr + '<br />' +
  //     "Injury Date: " + date + '<br />' +
  //     "Injury Description: " + desc + '<br />';
  //   this.setState({ isSending: true });

  //   apiClient.post("/api/Business/SendContact", {
  //     firstName: name + '<br />',
  //     lastName: " ",
  //     email: " ",
  //     message: messageBody,
  //   }).then(response => {
     
  //   }).catch(error => {
  //     Swal.close();
  //   });
  //   this.setState({ isSending: false });
  // };


  render() {
    const { isSending } = this.state;
    return (
      <>
        <div className="consultation-container">
          <div className="consultation-wrapper">
            <div className="consultation-header">
              <img src="https://www.elkandelk.com/wp-content/uploads/2019/03/hero_logo-01.svg" alt="logo" style={{width: '80px', height: '30px', marginRight:20}}/>
              <span>FREE CONSULTATION</span>
              </div>
            <div className="consultation-body">
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Name</div>
                    <input placeholder="Name" className="form-control name" id="name" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Address</div>
                    <input placeholder="Address" className="form-control address" id="address" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Email address</div>
                    <input placeholder="Email" className="form-control email" id="email" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Phone Number</div>
                    <InputMask placeholder="Phone Number" className="form-control phone" id="phone" mask="(999) 999-9999" maskChar=" " />
                  </label>
                </div>
              </div>              
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>How did you hear about us </div>
                    <textarea className="form-control injury-desc" id="injury-desc" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6 text-center">
                  <a
                    className="btn btn-primary submit-btn"
                    href="https://www.elkandelk.com/"
                    target="_blank" rel="noopener noreferrer"
                    style={{ padding: "10px"}}
                  >
                    Web Site
                  </a>
                </div>
                <div className="col-6 text-center">
                  <a
                    className="btn btn-primary submit-btn"
                    href="https://www.elkandelk.com/"
                    target="_blank" rel="noopener noreferrer"
                    style={{ padding: "10px"}}
                  >
                    Live Operator
                  </a>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isSending}
                    onClick={()=>{
                      Swal.fire({
                        type: 'success',
                        title: 'Success',
                        text: 'Request has been received!',
                      }).then(function () {
                        document.getElementById('name').value = "";
                        document.getElementById('address').value = "";
                        document.getElementById('email').value = "";
                        document.getElementById('phone').value = "";
                        document.getElementById('injury-desc').value = "";
                      });
                    }}
                  >
                    {isSending ? "Please wait..." : "SEND"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WinPage;