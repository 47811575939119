/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import classnames from 'classnames';
// import { AsYouType } from 'libphonenumber-js';
import Moment from 'react-moment';
import { Tag } from "@blueprintjs/core";

export const direction = {
  inbound: "Inbound",
  outbound: "Outbound"
};

export default class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    // this.handleLink = this.handleLink.bind(this);
  };

  // handleLink(text) {debugger
  //   var urlRegex = "/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig";
  //   return text.replace(urlRegex, function (url) {
  //     return '<a href="' + url + '">' + url + '</a>';
  //   });
  // }

  render() {
    let message = 'kt-chat__message';
    let text = 'kt-chat__text';
    if (direction.inbound === this.props.message.direction) {
      message = classnames(message, 'kt-chat__message--inbound');
    } else {
      message = classnames(message, 'kt-chat__message--right');
      message = classnames(message, 'kt-chat__outbound');
    }
    return <div className={message}>
      <div className="kt-chat__user">
        <span className="kt-chat__datetime">
          <Moment format="MMMM D LT">{this.props.message.createdDate}</Moment>
        </span>
      </div>
      <div className={text}>
        {/* this.handleLink(this.props.message.text) */}
        {this.props.message.text}
        {this.props.message.urls.length > 0 && this.props.message.urls[0].url !== null ?
          this.props.message.urls[0].isImage ?
            <img className="myImg" src={this.props.message.urls[0].url} width="100%" alt="content"></img>
            :
            this.props.message.urls[0].contentType.startsWith('video') || this.props.message.urls[0].contentType.startsWith('mp4') ?
              <video width="100%" controls>
                <source src={this.props.message.urls[0].url} type="video/mp4" />
                <source src={this.props.message.urls[0].url} type="video/ogg" />
                <source src={this.props.message.urls[0].url} type="video/webm" />
                Your browser does not support the video tag.
              </video>
              :
              <a href={this.props.message.urls[0].url} target="_blank">
                <div className="document-wrapper">
                  <Tag
                    intent="primary"
                    interactive={true}
                    icon="paperclip"
                    round={true}
                    large={true}
                    rightIcon="arrow-down"
                  >
                    Attachment
                  </Tag>
                </div>
              </a>
          : ""
        }
      </div>
    </div>;
  }
}