import React from 'react';
// import Icon from '@material-ui/core/Icon';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import mapmarker from "../../assets/images/icons/map-marker-alt-solid.svg"

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      latitude: null,
      longitude: null,
    };
  }

  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
    });
    if (address === "")
      this.props.handleAddress('', null, null);
  };

  handleSelect = selected => {
    // console.log(selected);
    this.setState({ address: selected });
    geocodeByAddress(selected)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.handleAddress(selected, lat, lng);
      })
      .catch(error => console.error('Error', error));
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
    this.props.handleAddress('', null, null);
  };

  render() {
    const {
      address
    } = this.state;
    return (
      <PlacesAutocomplete
        required
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        shouldFetchSuggestions={address.length > 2}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-container pb-1">
            <div className="search-input-container">
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  name: 'address',
                  className: 'location-search-input',
                })}
              />
              <span style={{ position: 'absolute' }}>
                <img src={mapmarker} alt="map-marker" style={{ width: "17px" }} />
              </span>
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    {}
                    {suggestion.formattedSuggestion.mainText}
                    {' '}
                    {suggestion.formattedSuggestion.secondaryText}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;