/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import apiClient from '../../components/Core/apiClient';
import { Typography, Row, Col, Modal, Input, notification } from 'antd';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;

class Question extends Component {
    constructor(props) {
        super(props);
        require('./question.scss');
        this.state = {
            node: this.props.node,
            id: this.props.node.id,
            sms: this.props.node.sms,
            mms: this.props.node.mms,
            modalVisible: false,
            deleteModalVisible: false
        };
        this.updateQuestion = this.updateQuestion.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    };

    componentDidMount() {
    }

    updateQuestion() {
        const contextThis = this;
        apiClient.post(`/api/Business/UpdateText/${this.state.id}`, {
            "sms": this.state.sms,
            "mms": this.state.mms
        }).then(response => {
            notification.success({
                message: 'text is updated successfully!'
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.log(`Axios request failed: ${error}`);
        });
        this.setState({ modalVisible: false });
    }

    deleteQuestion() {
        const contextThis = this;
        apiClient.post(`/api/Business/DeleteText/${this.state.id}`).then(response => {
            notification.success({
                message: 'text is deleted successfully!'
            });
            contextThis.setState({
                deleteModalVisible: false
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.log(`Axios request failed: ${error}`);
        });
    }

    addAnswer() {
        const contextThis = this;
        apiClient.post(`/api/Business/AddText/${this.props.packageId}`, {
            sms: "",
            mms: "",
            type: 1,
            action: 0,
            saveAnswer: false,
            dataTypeId: null,
            parentId: this.state.id,
            children: []
        }).then(response => {
            notification.success({
                message: 'text is added successfully!'
            });
            contextThis.props.refreshTextNodes();
        }).catch(error => {
            console.log(`Axios request failed: ${error}`);
        });
    }

    handleCancel() {
        this.setState({
            modalVisible: false
        });
        this.props.refreshTextNodes();
    }

    render() {
        return (
            <div className="question-item">
                <Row gutter={24} onClick={() => this.setState({ modalVisible: true })}>
                    <Col span={24}>
                        <Text>
                            {this.state.sms === '' ?
                                <Text style={{ color: '#fff' }}>&nbsp;</Text>
                                : <Text>{this.state.sms}</Text>}
                        </Text>
                    </Col>
                </Row>
                <Modal
                    title="Question"
                    visible={this.state.modalVisible}
                    onOk={this.updateQuestion}
                    okText="Save changes"
                    onCancel={() => this.handleCancel()}
                >
                    <Row gutter={24}>
                        <Col span={4}>SMS: </Col>
                        <Col span={20}>
                            <TextArea
                                autoSize
                                placeholder="SMS message"
                                defaultValue={this.state.sms}
                                style={{ width: '100%', minWidth: '350px' }}
                                onChange={(e) => this.setState({ sms: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '1rem' }}>
                        <Col span={4}>MMS: </Col>
                        <Col span={20}>
                            <TextArea
                                autoSize
                                placeholder="MMS message"
                                defaultValue={this.state.mms}
                                style={{ width: '100%', minWidth: '350px' }}
                                onChange={(e) => this.setState({ mms: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Modal>
                <div className="package-tool">
                    <span>
                        <a href={null} className="package-tool-link" onClick={this.addAnswer} title="add answer">
                            <PlusCircleOutlined />
                        </a>
                    </span>
                    <span>
                        <a href={null} className="package-tool-link" onClick={() => this.setState({ deleteModalVisible: true })} title="delete question">
                            <CloseCircleOutlined />
                        </a>
                        <Modal
                            title="Delete question"
                            visible={this.state.deleteModalVisible}
                            onOk={this.deleteQuestion}
                            okText="Yes"
                            onCancel={() => this.setState({ deleteModalVisible: false })}
                        >
                            Are you sure to delete this question?
                        </Modal>
                    </span>
                </div>
            </div >
        );
    }
}

export default Question;