import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ApplicationLayout from './containers/ApplicationLayout'
// layouts
import LandingPageLayout from './containers/LandingPageLayout'
import MobileLayout from './containers/MobileLayout'
import { Forgot, Login, Register, ResetPassword } from './pages/accounts'
import { Business, BusinessCreate, BusinessEdit } from './pages/business'
import Configuration from './pages/config'
import ContactInfo from './pages/contact-info'
import DataType from './pages/datatype'
import ElkandelkPage from './pages/elkandelk'
import EvaluationPage from './pages/evaluation'
import { Item, ItemCreate, ItemEdit } from './pages/keyword'
// pages
import LandingPage from './pages/landing-page'
import LocationLink from './pages/locationLink'
import Messaging from './pages/messaging'
import { Profile } from './pages/profile'
import { PaymentReport, UserRequestReport } from './pages/report'
import SearchResult from './pages/search-result/search-result'
import { User, UserCreate, UserEdit } from './pages/user'
import * as serviceWorker from './serviceWorker'

const fakeAuth = (role) => {
  if (localStorage.getItem('isAuthenticated') === 'false') return false

  let roles = localStorage.getItem('roles')
  if (roles == null) return false

  let result = false
  role.map((o) => {
    if (roles.includes(o)) {
      result = true
    }
    return o
  })
  return result
}

const AppRoute = ({ component: Component, layout: Layout, withoutMenu: menu, authed, ...rest }) =>
  authed === true ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout withoutMenu={menu}>
          <Component {...props} />
        </Layout>
      )}
    />
  ) : (
    <Route
      {...rest}
      render={(props) => (
        <Layout withoutMenu={false}>
          <Login {...props} />
        </Layout>
      )}
    />
  )

const App = () => {
  var isiPad = navigator.userAgent.match(/iPad/i) !== null
  var isiPhone = navigator.userAgent.match(/iPhone/i) !== null
  var isiPod = navigator.userAgent.match(/iPod/i) !== null
  var isAndroid1 = navigator.userAgent.toLowerCase().indexOf('android') > -1
  var isAndroid2 = /(android)/i.test(navigator.userAgent)
  if (isiPad || isiPhone || isiPod || isAndroid1 || isAndroid2) {
    return (
      <Router forceRefresh={true}>
        <Switch>
          <AppRoute path="/" exact layout={ApplicationLayout} component={Login} withoutMenu={true} authed={true} />
          <AppRoute path="/evaluation" exact layout={LandingPageLayout} component={EvaluationPage} authed={true} />
          <AppRoute path="/elkandelk" exact layout={LandingPageLayout} component={ElkandelkPage} authed={true} />
          <AppRoute path="/locationLink/:id" exact layout={LandingPageLayout} component={LocationLink} withoutMenu={true} authed={true} />
          <AppRoute path="/search-result" exact layout={ApplicationLayout} component={SearchResult} withoutMenu={true} authed={true} />
          <AppRoute path="/contact-info" exact layout={ApplicationLayout} component={ContactInfo} withoutMenu={true} authed={true} />
          <AppRoute path="/login" exact layout={ApplicationLayout} component={Login} withoutMenu={true} authed={true} />
          <AppRoute path="/forgot" exact layout={ApplicationLayout} component={Forgot} withoutMenu={true} authed={true} />
          <AppRoute path="/register/:id" exact layout={ApplicationLayout} component={Register} withoutMenu={true} authed={true} />
          <AppRoute path="/profile" layout={MobileLayout} />
          <AppRoute path="/items" layout={MobileLayout} />
          <AppRoute path="/messaging" layout={MobileLayout} />
          <AppRoute path="/reports" layout={MobileLayout} />
          <AppRoute path="/users" layout={MobileLayout} />
          <AppRoute path="/admin" layout={MobileLayout} />
          <AppRoute path="/config" layout={MobileLayout} />
        </Switch>
      </Router>
    )
  } else {
    return (
      <Router forceRefresh={true}>
        <Switch>
          <AppRoute path="/" exact layout={ApplicationLayout} component={Login} withoutMenu={true} authed={true} />
          <AppRoute path="/evaluation" exact layout={LandingPageLayout} component={EvaluationPage} authed={true} />
          <AppRoute path="/elkandelk" exact layout={LandingPageLayout} component={ElkandelkPage} authed={true} />
          <AppRoute path="/profile" exact layout={ApplicationLayout} component={Profile} authed={fakeAuth(['Attorney', 'Staff', 'Admin'])} />
          <AppRoute path="/resetpassword" exact layout={ApplicationLayout} component={ResetPassword} authed={true} />
          <AppRoute path="/locationLink/:id" exact layout={LandingPageLayout} component={LocationLink} withoutMenu={true} authed={true} />
          <AppRoute path="/search-result" exact layout={ApplicationLayout} component={SearchResult} withoutMenu={true} authed={true} />
          <AppRoute path="/contact-info" exact layout={ApplicationLayout} component={ContactInfo} withoutMenu={true} authed={true} />
          <AppRoute path="/login" exact layout={ApplicationLayout} component={Login} withoutMenu={true} authed={true} />
          <AppRoute path="/forgot" exact layout={ApplicationLayout} component={Forgot} withoutMenu={true} authed={true} />
          <AppRoute path="/register/:id" exact layout={ApplicationLayout} component={Register} withoutMenu={true} authed={true} />
          <AppRoute path="/messaging" exact layout={ApplicationLayout} component={Messaging} authed={fakeAuth(['Attorney', 'Staff'])} />
          <AppRoute path="/admin/users" exact layout={ApplicationLayout} component={User} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/admin/users/create" exact layout={ApplicationLayout} component={UserCreate} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/admin/users/edit/:id" exact layout={ApplicationLayout} component={UserEdit} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/users" exact layout={ApplicationLayout} component={(...props) => <User {...props} isAdmin={false}></User>} authed={fakeAuth(['Attorney'])} />
          <AppRoute path="/users/create" exact layout={ApplicationLayout} component={(props) => <UserCreate {...props} isAdmin={false}></UserCreate>} authed={fakeAuth(['Attorney'])} />
          <AppRoute path="/users/edit/:id" exact layout={ApplicationLayout} component={(props) => <UserEdit {...props} isAdmin={false}></UserEdit>} authed={fakeAuth(['Attorney'])} />
          <AppRoute path="/admin/businesses" exact layout={ApplicationLayout} component={Business} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/admin/business/create" exact layout={ApplicationLayout} component={BusinessCreate} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/admin/business/edit/:id" exact layout={ApplicationLayout} component={BusinessEdit} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/config" exact layout={ApplicationLayout} component={Configuration} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/datatypes" exact layout={ApplicationLayout} component={DataType} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/items" exact layout={ApplicationLayout} component={Item} authed={fakeAuth(['Admin', 'Attorney'])} />
          <AppRoute path="/items/edit/:id" exact layout={ApplicationLayout} component={ItemEdit} authed={fakeAuth(['Admin', 'Attorney'])} />
          <AppRoute path="/items/create" exact layout={ApplicationLayout} component={ItemCreate} authed={fakeAuth(['Admin', 'Attorney'])} />
          <AppRoute path="/admin/reports" exact layout={ApplicationLayout} component={UserRequestReport} authed={fakeAuth(['Admin'])} />
          <AppRoute path="/reports" exact layout={ApplicationLayout} component={(...props) => <UserRequestReport {...props} isAdmin={false}></UserRequestReport>} authed={fakeAuth(['Attorney'])} />
          <AppRoute path="/admin/payments" exact layout={ApplicationLayout} component={PaymentReport} authed={fakeAuth(['Admin'])} />
          <AppRoute layout={LandingPageLayout} component={LandingPage} authed={true} />
        </Switch>
      </Router>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
