import React, { Component } from 'react';

class AsideMenuSubItem extends Component {
    render() {
        const title = this.props.title;
        return (
            <li className="kt-menu__item  kt-menu__item--submenu" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
                <div className="kt-menu__link kt-menu__toggle kt-menu__bold kt-menu__color">
                    <span className="kt-menu__link-text">{title}</span>
                </div>
                <div className="kt-menu__submenu ">
                    <ul className="kt-menu__subnav">
                        {this.props.children}
                    </ul>
                </div>
            </li>
        );
    }
}

export default AsideMenuSubItem;