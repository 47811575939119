import React, { Component } from 'react';
import Swal from "sweetalert2";
import apiClient from '../../components/Core/apiClient';
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class WinPage extends Component {
  constructor(props) {
    super(props);
    require("./evaluation.scss");
    this.state = {
      isSending: false,
      startDate: new Date()
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = event => {
    event.preventDefault();
    var name = document.getElementById('name').value;
    var address = document.getElementById('address').value;
    var phone = document.getElementById('phone').value;
    var type = document.getElementById('injury-type');
    var typeStr = type.options[type.selectedIndex].text;
    var date = document.getElementById('injury-date').value;
    var desc = document.getElementById('injury-desc').value;

    var messageBody =
      "<br /><br />Address: " + address + '<br />' +
      "Phone Number: " + phone + '<br />' +
      "Injury Type: " + typeStr + '<br />' +
      "Injury Date: " + date + '<br />' +
      "Injury Description: " + desc + '<br />';
    this.setState({ isSending: true });

    apiClient.post("api/Business/SendContact", {
      firstName: name + '<br />',
      lastName: " ",
      email: " ",
      message: messageBody,
    }).then(response => {
      Swal.fire({
        type: 'success',
        title: 'Success',
        text: 'Case has been successfully sent.',
      }).then(function () {
        document.getElementById('name').value = "";
        document.getElementById('address').value = "";
        document.getElementById('phone').value = "";
        document.getElementById('injury-type').value = 0;
        document.getElementById('injury-desc').value = "";
      });
    }).catch(error => {
      Swal.close();
    });
    this.setState({ isSending: false });
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  render() {
    const { isSending } = this.state;
    return (
      <>
        <div className="eval-container">
          <div className="eval-wrapper">
            <div className="eval-header"><span style={{ color: '#9ec8ff' }}>FREE</span> CASE EVALUATION</div>
            <div className="eval-body">
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Name</div>
                    <input placeholder="Name" className="form-control name" id="name" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Address</div>
                    <input placeholder="Address" className="form-control address" id="address" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Phone Number</div>
                    <InputMask placeholder="Phone Number" className="form-control phone" id="phone" mask="(999) 999-9999" maskChar=" " />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>How have you been injured? </div>
                    <select className="form-control injury-type" id="injury-type" >
                      <option value="0">Auto Accident</option>
                      <option value="1">Slip Fall</option>
                      <option value="2">Worker’s Compensation </option>
                      <option value="3">Medical Malpractice </option>
                      <option value="4">Wrongful Death</option>
                      <option value="5">Other</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>When did this happen? </div>
                    <DatePicker
                      className="form-control injury-date"
                      id="injury-date"
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>
                    <div>Briefly explain your injury and what happened? </div>
                    <textarea className="form-control injury-desc" id="injury-desc" />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary submit-btn"
                    onClick={this.handleSubmit}
                    disabled={isSending}
                  >
                    {isSending ? "Please wait..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WinPage;