import React, { Component }  from 'react';

class PhoneImage extends Component {
	render() {
		return (
            <div className="header-right-image">
				<img src={this.props.image} alt="header right img" />
            </div>         
		);
	}
}

export default PhoneImage;